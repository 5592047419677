export default [
  /// Homologação, testes e OTRS
  {
    id: 'genesis',
    url: 'https://lumen.clinux.com.br/chamados/cgi-bin/dwserver.cgi',
    tmp: 'https://lumen.clinux.com.br/chamados'
  },
  {
    id: 'freebsd',
    url: 'http://localhost/cgi-bin/dwserver.cgi',
    tmp: 'http://localhost'
  },
  {
    id: 'localhost',
    url: 'http://localhost:8082',
    tmp: 'http://localhost:8082'
  },
  {
    id: 'comercial',
    url: 'https://lumen.clinux.com.br/genesis/cgi-bin/dwserver.cgi',
    tmp: 'https://lumen.clinux.com.br/genesis'
  },
  // Endereço incluso na listagem estática para casos onde não exista conexão externa com a internet
  {
    id: 'sedi2-local',
    url: 'https://sedi2-local.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'https://sedi2-local.zapto.org'
  },
  {
    id: 'telelaudo',
    url: 'https://portal.telelaudo.com.br/cgi-bin/dwserver.cgi',
    tmp: 'https://portal.telelaudo.com.br'
  }
  /*
  /// Clientes
  // A
  {
    id: 'alfenas',
    url: 'http://alfenas.zapto.org:90/cgi-bin/dwserver.cgi',
    tmp: 'http://alfenas.zapto.org:90'
  },
  {
    id: 'annanery',
    url: 'http://clinux.medlifesosdf.com.br:8085/annanery/cgi-bin/dwserver.cgi',
    tmp: 'http://clinux.medlifesosdf.com.br:8085/annanery'
  },
  {
    id: 'albertsabin',
    url: 'http://clinux.medlifesosdf.com.br:8085/albertsabin/cgi-bin/dwserver.cgi',
    tmp: 'http://clinux.medlifesosdf.com.br:8085/albertsabin'
  },
  {
    id: 'anatoscanrj',
    url: 'http://anato.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://anato.zapto.org'
  },
  // B
  {
    id: 'bdi',
    url: 'http://bdi.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://bdi.zapto.org'
  },
  {
    id: 'bertinetti',
    url: 'http://bertinetti.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://bertinetti.zapto.org:9090'
  },
  {
    id: 'bild',
    url: 'http://bild.zapto.org:83/cgi-bin/dwserver.cgi',
    tmp: 'http://bild.zapto.org:83'
  },
  {
    id: 'bioscan',
    url: 'http://bioscan.redirectme.net/cgi-bin/dwserver.cgi',
    tmp: 'http://bioscan.redirectme.net'
  },
  {
    id: 'bioimagemtc',
    url: 'http://bioimagem.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://bioimagem.ddns.net'
  },
  {
    id: 'bioimagem_mg',
    url: 'http://bioimagem.redirectme.net/cgi-bin/dwserver.cgi',
    tmp: 'http://bioimagem.redirectme.net'
  },
  {
    id: 'bioradio',
    url: 'http://bioradio.ddns.me:83/cgi-bin/dwserver.cgi',
    tmp: 'http://bioradio.ddns.me:83'
  },
  // C
  {
    id: 'cantagalo',
    url: 'http://climagemcantagalo.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://climagemcantagalo.zapto.org:9090'
  },
  {
    id: 'cdaaparecida',
    url: 'http://cdaaparecida.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://cdaaparecida.zapto.org'
  },
  {
    id: 'cedimagemituiatuba',
    url: 'http://cedimagemweb.ddns.me/cgi-bin/dwserver.cgi',
    tmp: 'http://cedimagemweb.ddns.me'
  },
  {
    id: 'centroimagemcabofrio',
    url: 'http://centrodeimagens.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://centrodeimagens.zapto.org:9090'
  },
  {
    id: 'centroimagemcabofrio-homologacao',
    url: 'http://centrodeimagens.zapto.org:9090/homologacao/dwserver.cgi',
    tmp: 'http://centrodeimagens.zapto.org:9090/homologacao'
  },
  {
    id: 'centroimagembertinetti',
    url: 'http://centroimagembertinetti.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://centroimagembertinetti.zapto.org'
  },
  {
    id: 'cdibarreiro',
    url: 'http://cdimatriz.dyndns.org:8089/cgi-bin/dwserver.cgi',
    tmp: 'http://cdimatriz.dyndns.org:8089'
  },
  {
    id: 'cdicabofrio',
    url: 'http://centrodeimagens.zapto.org:2223/cgi-bin/dwserver.cgi',
    tmp: 'http://centrodeimagens.zapto.org:2223'
  },
  {
    id: 'cdicolatina',
    url: 'http://cdicolatina.zapto.org:83/cgi-bin/dwserver.cgi',
    tmp: 'http://cdicolatina.zapto.org:83'
  },
  {
    id: 'cediitaocara',
    url: 'http://cediitaocara.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://cediitaocara.zapto.org:9090'
  },
  {
    id: 'cdiudi',
    url: 'http://cdiub.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://cdiub.zapto.org:9090'
  },
  {
    id: 'cedus',
    url: 'http://cedusmat.ddns.net:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://cedusmat.ddns.net:9090'
  },
  {
    id: 'cetamsp',
    url: 'http://cetamsp.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://cetamsp.zapto.org'
  },
  {
    id: 'cdibh',
    url: 'http://cdimatriz.dyndns.org:8089/cgi-bin/dwserver.cgi',
    tmp: 'http://cdimatriz.dyndns.org:8089'
  },
  {
    id: 'cdisalvador',
    url: 'http://cdisalvador.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://cdisalvador.zapto.org'
  },
  {
    id: 'cdmcn',
    url: 'http://cdmcn.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://cdmcn.zapto.org:9090'
  },
  {
    id: 'cdmhosp',
    url: 'http://cdmhosp.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://cdmhosp.zapto.org:9090'
  },
  {
    id: 'cdmimagem',
    url: 'http://cdmimagem.redirectme.net:9080/cgi-bin/dwserver.cgi',
    tmp: 'http://cdmimagem.redirectme.net:9080'
  },
  {
    id: 'cdmultimagem',
    url: 'http://cdmultimagem.ddns.net:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://cdmultimagem.ddns.net:9090'
  },
  {
    id: 'cdt',
    url: 'http://centrodiagnostico.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://centrodiagnostico.zapto.org'
  },
  {
    id: 'cedicordeiro',
    url: 'http://189.90.118.132/cgi-bin/dwserver.cgi',
    tmp: 'http://189.90.118.132'
  },
  {
    id: 'ceico',
    url: 'http://ceico.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://ceico.zapto.org:9090'
  },
  {
    id: 'centrointegrado',
    url: 'http://oestemais.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://oestemais.ddns.net'
  },
  {
    id: 'centropinhalense',
    url: 'http://centropinhalense.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://centropinhalense.zapto.org:9090'
  },
  {
    id: 'cepem',
    url: 'http://177.124.226.98:8080/cgi-bin/dwserver.cgi',
    tmp: 'http://177.124.226.98:8080'
  },
  {
    id: 'ceu',
    url: 'http://177.69.225.196:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://177.69.225.196:9090'
  },
  {
    id: 'checkupmedicina',
    url: 'http://checkupmedicina.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://checkupmedicina.ddns.net'
  },
  {
    id: 'cidipatinga',
    url: 'http://cid.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://cid.ddns.net'
  },
  {
    id: 'cimag',
    url: 'http://cimag.noip.us:8080/cgi-bin/dwserver.cgi',
    tmp: 'http://cimag.noip.us:8080'
  },
  {
    id: 'cisp',
    url: 'http://cdsp.redirectme.net:85/cgi-bin/dwserver.cgi',
    tmp: 'http://cdsp.redirectme.net:85'
  },
  {
    id: 'clinicadelfos',
    url: 'http://clinicadelfos.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicadelfos.zapto.org'
  },
  {
    id: 'clinicasantaluzia',
    url: 'http://clinicasantaluzia.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicasantaluzia.zapto.org'
  },
  {
    id: 'clinicasantapaula',
    url: 'http://clinicasantapaula.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicasantapaula.zapto.org:9090'
  },
  {
    id: 'clinicasaomiguelarcanjo',
    url: 'http://gcsma.redirectme.net:81/cgi-bin/dwserver.cgi',
    tmp: 'http://gcsma.redirectme.net:81'
  },
  {
    id: 'clinicasaosalvador',
    url: 'http://clinicasaosalvador.ddns.net:81/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicasaosalvador.ddns.net:81'
  },
  {
    id: 'clinicaceraji',
    url: 'http://clinicaceraji.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicaceraji.zapto.org'
  },
  {
    id: 'clinicamagnecamp',
    url: 'http://clinicamagnecamp.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicamagnecamp.ddns.net'
  },
  {
    id: 'clinicavita',
    url: 'http://187.115.65.219:9091/cgi-bin/dwserver.cgi',
    tmp: 'http://187.115.65.219:9091'
  },
  {
    id: 'clinimagem',
    url: 'http://clinimagem.zapto.org:90/cgi-bin/dwserver.cgi',
    tmp: 'http://clinimagem.zapto.org:90'
  },
  {
    id: 'climagemgoianesia',
    url: 'http://climagem.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://climagem.zapto.org:9090'
  },
  {
    id: 'climagemitaborai',
    url: 'http://climagemitaborai.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://climagemitaborai.zapto.org:9090'
  },
  {
    id: 'clinicamulticlin',
    url: 'http://clinicamulticlin.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicamulticlin.ddns.net'
  },
  {
    id: 'clirad',
    url: 'http://clirad.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://clirad.zapto.org'
  },
  {
    id: 'cmsv',
    url: 'http://cmsv.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://cmsv.zapto.org'
  },
  {
    id: 'conrad',
    url: 'http://conrad.ddns.me:8090/cgi-bin/dwserver.cgi',
    tmp: 'http://conrad.ddns.me:8090/'
  },
  {
    id: 'cotreu',
    url: 'http://cotreu.redirectme.net/cgi-bin/dwserver.cgi',
    tmp: 'http://cotreu.redirectme.net'
  },
  {
    id: 'crbimagem',
    url: 'http://dvrcopcrb.sistemacop.info:81/cgi-bin/dwserver.cgi',
    tmp: 'http://dvrcopcrb.sistemacop.info:81'
  },
  {
    id: 'crd',
    url: 'http://crd.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://crd.zapto.org'
  },
  {
    id: 'ctr',
    url: 'http://ctrelo.dyndns.org:180/cgi-bin/dwserver.cgi',
    tmp: 'http://ctrelo.dyndns.org:180'
  },
  // D
  {
    id: 'davirezende',
    url: 'http://davirezende.ddns.me:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://davirezende.ddns.me:9090'
  },
  {
    id: 'dexamedicina',
    url: 'http://clinicadexa.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicadexa.zapto.org:9090'
  },
  {
    id: 'diagnocor',
    url: 'http://diagnocor.no-ip.org:7908/cgi-bin/dwserver.cgi',
    tmp: 'http://diagnocor.no-ip.org:7908'
  },
  {
    id: 'diagnosticosavancados',
    url: 'http://diagnosticosavancados.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://diagnosticosavancados.zapto.org'
  },
  {
    id: 'diagnostik',
    url: 'http://diagnostik.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://diagnostik.zapto.org'
  },
  {
    id: 'duodiagnosticos',
    url: 'http://duodiagnosticos.ddns.net:2323/cgi-bin/dwserver.cgi',
    tmp: 'http://duodiagnosticos.ddns.net:2323'
  },
  // E
  {
    id: 'ecp',
    url: 'http://170.239.227.25:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://170.239.227.25:9090'
  },
  {
    id: 'ekosom',
    url: 'http://ekosom.zapto.org:18081/cgi-bin/dwserver.cgi',
    tmp: 'http://ekosom.zapto.org:18081'
  },
  {
    id: 'eldiagnostico',
    url: 'http://eldiagnostico.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://eldiagnostico.zapto.org'
  },
  {
    id: 'examina',
    url: 'http://examina.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://examina.zapto.org'
  },
  // F
  {
    id: 'futuro',
    url: 'http://segmedic.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://segmedic.zapto.org'
  },
  {
    id: 'freigaspar',
    url: 'http://freigaspar.zapto.org:83/cgi-bin/dwserver.cgi',
    tmp: 'http://freigaspar.zapto.org:83'
  },
  // G
  {
    id: 'gastren',
    url: 'http://gastren.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://gastren.zapto.org'
  },
  {
    id: 'guimaraesecarvalho',
    url: 'http://guimaraesecarvalho.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://guimaraesecarvalho.zapto.org'
  },
  // H
  {
    id: 'hbraganca',
    url: 'http://hbraganca.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://hbraganca.zapto.org:9090'
  },
  {
    id: 'hosphugo',
    url: 'http://hosphugo.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://hosphugo.zapto.org'
  },
  {
    id: 'hospsantarita',
    url: 'https://hospsantarita.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'https://hospsantarita.zapto.org'
  },
  {
    id: 'hospsantarita-homologacao',
    url: 'https://hospsantarita.zapto.org/homologacao/dwserver.cgi',
    tmp: 'https://hospsantarita.zapto.org'
  },
  {
    id: 'hucam',
    url: 'http://riss.hucam.edu.br/cgi-bin/dwserver.cgi',
    tmp: 'http://riss.hucam.edu.br'
  },
  // I
  {
    id: 'ibmed',
    url: 'http://ibmed.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://ibmed.zapto.org'
  },
  {
    id: 'ibnceres',
    url: 'http://ibn.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://ibn.zapto.org'
  },
  {
    id: 'ices',
    url: 'http://webmail.instituto-es.org.br:81/cgi-bin/dwserver.cgi',
    tmp: 'http://webmail.instituto-es.org.br:81'
  },
  {
    id: 'ihamg',
    url: 'http://ihamg.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://ihamg.ddns.net'
  },
  {
    id: 'imagemservicodeus',
    url: 'http://138.121.52.88/cgi-bin/dwserver.cgi',
    tmp: 'http://138.121.52.88'
  },
  {
    id: 'imagenologia',
    url: 'http://unimedco.redirectme.net:3380/cgi-bin/dwserver.cgi',
    tmp: 'http://unimedco.redirectme.net:3380'
  },
  {
    id: 'imagenscuiaba',
    url: 'http://imagenscuiaba.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://imagenscuiaba.zapto.org:9090'
  },
  {
    id: 'imedicuiaba',
    url: 'http://genesis.imedionline.com.br/cgi-bin/dwserver.cgi',
    tmp: 'http://genesis.imedionline.com.br'
  },
  {
    id: 'imr',
    url: 'http://imr.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://imr.zapto.org'
  },
  {
    id: 'institutoderadiologia',
    url: 'http://institutoderadiologia.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://institutoderadiologia.zapto.org'
  },
  {
    id: 'institutoma',
    url: 'http://institutoma.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://institutoma.zapto.org'
  },
  // L
  {
    id: 'lifescan',
    url: 'http://megaunidade.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://megaunidade.zapto.org:9090'
  },
  {
    id: 'linhares',
    url: 'http://bioscan-linhares.redirectme.net/cgi-bin/dwserver.cgi',
    tmp: 'http://bioscan-linhares.redirectme.net'
  },
  {
    id: 'lm',
    url: 'http://genesistec.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://genesistec.zapto.org:9090'
  },
  // M
  {
    id: 'magnetom',
    url: 'http://magnetomed.redirectme.net:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://magnetomed.redirectme.net:9090'
  },
  {
    id: 'magnusitajuba',
    url: 'http://49898.g6i.me/cgi-bin/dwserver.cgi',
    tmp: 'http://49898.g6i.me'
  },
  {
    id: 'medcenter',
    url: 'http://cdrdiagnosticos.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://cdrdiagnosticos.ddns.net'
  },
  {
    id: 'medcentermg',
    url: 'http://medcentermg.ddns.net:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://medcentermg.ddns.net:9090'
  },
  {
    id: 'meddiagnosticos',
    url: 'http://meddiagnosticos.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://meddiagnosticos.zapto.org:9090'
  },
  {
    id: 'medicine',
    url: 'http://masterclinica.zapto.org:39803/cgi-bin/dwserver.cgi',
    tmp: 'http://masterclinica.zapto.org:39803'
  },
  {
    id: 'medimagemmaranhao',
    url: 'http://177.185.141.71:99/cgi-bin/dwserver.cgi',
    tmp: 'http://177.185.141.71:99'
  },
  {
    id: 'megaimagem',
    url: 'http://megaimagem.zapto.org/producao/cgi-bin/dwserver.cgi',
    tmp: 'http://megaimagem.zapto.org/producao'
  },
  {
    id: 'megaimagem_homologacao',
    url: 'http://megaimagem.zapto.org/homologacao/cgi-bin/dwserver.cgi',
    tmp: 'http://megaimagem.zapto.org/homologacao'
  },
  {
    id: 'medcare',
    url: 'http://medcare.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://medcare.zapto.org'
  },
  {
    id: 'medicalsti',
    url: 'http://medicalsti.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://medicalsti.ddns.net'
  },
  {
    id: 'medscanlagos',
    url: 'http://clinicamedscanlagos.zapto.org:2121/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicamedscanlagos.zapto.org:2121'
  },
  {
    id: 'metrocor',
    url: 'http://metrocor.ddns.net:8000/cgi-bin/dwserver.cgi',
    tmp: 'http://metrocor.ddns.net:8000'
  },
  {
    id: 'multimagem',
    url: 'http://multimagem.redirectme.net/cgi-bin/dwserver.cgi',
    tmp: 'http://multimagem.redirectme.net'
  },
  // N
  {
    id: 'neurodiag',
    url: 'http://neurodiag.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://neurodiag.ddns.net'
  },
  // O
  {
    id: 'ortolife',
    url: 'http://ortolife.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://ortolife.zapto.org:9090'
  },
  {
    id: 'ottokeppke',
    url: 'http://ottokeppke.redirectme.net/cgi-bin/dwserver.cgi',
    tmp: 'http://ottokeppke.redirectme.net'
  },
  // P
  {
    id: 'policlinicabotafogo',
    url: 'http://arquivo4.dnset.com:81/cgi-bin/dwserver.cgi',
    tmp: 'http://arquivo4.dnset.com:81'
  },
  {
    id: 'pomerodesc',
    url: 'http://189.45.198.63/cgi-bin/dwserver.cgi',
    tmp: 'http://189.45.198.63'
  },
  {
    id: 'pontuallaudos',
    url: 'https://portal.pontuallaudos.com.br/cgi-bin/dwserver.cgi',
    tmp: 'https://portal.pontuallaudos.com.br'
  },
  {
    id: 'portosegurodiagnosticos',
    url: 'http://cdm-porto.no-ip.org:8081/cgi-bin/dwserver.cgi',
    tmp: 'http://cdm-porto.no-ip.org:8081'
  },
  // Q
  {
    id: 'qualimagem',
    url: 'http://qualimagemrm.zapto.org:8880/cgi-bin/dwserver.cgi',
    tmp: 'http://qualimagemrm.zapto.org:8880/cgi-bin/dwserver.cgi'
  },
  // R
  {
    id: 'radioclin',
    url: 'http://849707f52167.sn.mynetname.net/cgi-bin/dwserver.cgi',
    tmp: 'http://849707f52167.sn.mynetname.net'
  },
  {
    id: 'radiologiasidney',
    url: 'http://radiologiasidney.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://radiologiasidney.zapto.org'
  },
  {
    id: 'radmed',
    url: 'http://radmed.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://radmed.zapto.org'
  },
  {
    id: 'radscan',
    url: 'http://radscan.ddns.net:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://radscan.ddns.net:9090'
  },
  {
    id: 'refraktum',
    url: 'http://clinicaep.dyndns.org/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicaep.dyndns.org'
  },
  {
    id: 'rossetti',
    url: 'http://rossetti.ddns.cyberoam.com/cgi-bin/dwserver.cgi',
    tmp: 'http://rossetti.ddns.cyberoam.com'
  },
  // S
  {
    id: 'salles',
    url: 'http://pacssalles.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://pacssalles.zapto.org'
  },
  {
    id: 'saluta',
    url: 'http://saluta.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://saluta.zapto.org'
  },
  {
    id: 'santaana',
    url: 'http://staana.thinkit.srv.br:880/cgi-bin/dwserver.cgi',
    tmp: 'http://staana.thinkit.srv.br:880'
  },
  {
    id: 'santacasajoaobarra',
    url: 'http://santacasajoaobarra.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://santacasajoaobarra.ddns.net'
  },
  {
    id: 'santarosa',
    url: 'http://dicomvix.santarosaimagem.com.br:89/cgi-bin/dwserver.cgi',
    tmp: 'http://dicomvix.santarosaimagem.com.br:89'
  },
  {
    id: 'santahelena',
    url: 'http://pacs.hsh.com.br/cgi-bin/dwserver.cgi',
    tmp: 'http://pacs.hsh.com.br'
  },
  {
    id: 'sagradocoracao',
    url: 'http://sagradocoracao.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://sagradocoracao.zapto.org:9090'
  },
  {
    id: 'saomarcelo',
    url: 'http://saomarcelo.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://saomarcelo.zapto.org:9090'
  },
  {
    id: 'scanner',
    url: 'http://scanner.no-ip.net:8383/cgi-bin/dwserver.cgi',
    tmp: 'http://scanner.no-ip.net:8383'
  },
  {
    id: 'sedi2',
    url: 'https://sedi2.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'https://sedi2.zapto.org'
  },
  {
    id: 'segmedic',
    url: 'http://segmedic.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://segmedic.zapto.org'
  },
  {
    id: 'semfvespasiano',
    url: 'http://semfvespasiano.ddns.net:8001/cgi-bin/dwserver.cgi',
    tmp: 'http://semfvespasiano.ddns.net:8001'
  },
  {
    id: 'sensumed',
    url: 'http://sensumed.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://sensumed.zapto.org:9090'
  },
  {
    id: 'sermig',
    url: 'http://pacs-sermig.ddns.me:83/cgi-bin/dwserver.cgi',
    tmp: 'http://pacs-sermig.ddns.me:83'
  },
  {
    id: 'sermighpm',
    url: 'http://pacs-hpm.ddns.me:83/cgi-bin/dwserver.cgi',
    tmp: 'http://pacs-hpm.ddns.me:83'
  },
  {
    id: 'sono',
    url: 'http://sono.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://sono.zapto.org:9090'
  },
  {
    id: 'spingv',
    url: 'http://spingv.redirectme.net/cgi-bin/dwserver.cgi',
    tmp: 'http://spingv.redirectme.net'
  },
  {
    id: 'srd',
    url: 'http://srd.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://srd.zapto.org'
  },
  {
    id: 'sulbahia',
    url: 'http://sulbahia.zapto.org:8080/cgi-bin/dwserver.cgi',
    tmp: 'http://sulbahia.zapto.org:8080'
  },
  {
    id: 'susga',
    url: 'http://susga.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://susga.zapto.org'
  },
  // T
  {
    id: 'tadachimagem',
    url: 'http://clinicatadachimagem.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicatadachimagem.zapto.org'
  },
  {
    id: 'telelaudo',
    url: 'https://portal.telelaudo.com.br/cgi-bin/dwserver.cgi',
    tmp: 'https://portal.telelaudo.com.br'
  },
  {
    id: 'telelaudo-testes',
    url: 'https://pacstelelaudo.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'https://pacstelelaudo.zapto.org'
  },
  {
    id: 'tomoclinica',
    url: 'http://webpacs.tomoclinica.com.br/cgi-bin/dwserver.cgi',
    tmp: 'http://webpacs.tomoclinica.com.br'
  },
  {
    id: 'tomovale',
    url: 'http://tomovale.zapto.org/cgi-bin/dwserver.cgi',
    tmp: 'http://tomovale.zapto.org'
  },
  {
    id: 'tomovalerm',
    url: 'http://tomovalerm.zapto.org:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://tomovalerm.zapto.org:9090'
  },
  {
    id: 'triad',
    url: 'https://pacs.triad.med.br/cgi-bin/dwserver.cgi',
    tmp: 'https://pacs.triad.med.br'
  },
  // U
  {
    id: 'ubatuba',
    url: 'http://ubatuba.no-ip.org:90/cgi-bin/dwserver.cgi',
    tmp: 'http://ubatuba.no-ip.org:90'
  },
  {
    id: 'ultramed13',
    url: 'http://ultramed13.ddns.com.br/cgi-bin/dwserver.cgi',
    tmp: 'http://ultramed13.ddns.com.br'
  },
  {
    id: 'ultramedangra',
    url: 'http://ultramed-angra.ddns.net/cgi-bin/dwserver.cgi',
    tmp: 'http://ultramed-angra.ddns.net'
  },
  {
    id: 'unimedcriciuma',
    url: 'http://imagem.criciuma.unimedsc.com.br/cgi-bin/dwserver.cgi',
    tmp: 'http://imagem.criciuma.unimedsc.com.br'
  },
  {
    id: 'unimagem',
    url: 'http://pacsunimagem.com.br:81/cgi-bin/dwserver.cgi',
    tmp: 'http://pacsunimagem.com.br:81'
  },
  {
    id: 'unineuro',
    url: 'http://clinicaunineuro.ddns.net:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://clinicaunineuro.ddns.net:9090'
  },
  {
    id: 'unirad_pacheco',
    url: 'http://179.183.31.110/cgi-bin/dwserver.cgi',
    tmp: 'http://179.183.31.110'
  },
  // V
  {
    id: 'vidadiagnosticos',
    url: 'http://vidadiagnosticos.zapto.org:89/cgi-bin/dwserver.cgi',
    tmp: 'http://vidadiagnosticos.zapto.org:89'
  },
  {
    id: 'vidaoto',
    url: 'http://vidaoto.zapto.org:9080/cgi-bin/dwserver.cgi',
    tmp: 'http://vidaoto.zapto.org:9080'
  },
  {
    id: 'vnra',
    url: 'http://8eea085754d3.sn.mynetname.net:9090/cgi-bin/dwserver.cgi',
    tmp: 'http://8eea085754d3.sn.mynetname.net:9090'
  },
  {
    id: 'visionmedicina',
    url: 'http://vision.dynalias.net/cgi-bin/dwserver.cgi',
    tmp: 'http://vision.dynalias.net'
  }
  */
]
