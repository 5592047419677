import api from '@/services/api'
import ApiLaudo from '@/services/laudo'
import { getField, updateField } from 'vuex-map-fields'
// import store from '@/store'
import groupBy from 'lodash/groupBy'
import { saveAs } from 'file-saver'

export default {
  namespaced: true,
  state: {
    modalJpeg: false,
    snAvisoJava: false,
    modalAvisoJava: false,
    urlCloseWindowOsirix: 'osirix://?methodName=CloseAllWindows',
    listaJpeg: [],
    viewers: []
  },
  actions: {
    carregarViewer ({ rootState, state }) {
      const snMacOs = navigator.userAgent.match(/\(mac/gmi)
      state.viewers = [
        { icone: 'mdi-image-area', texto: 'Osirix', acao: 'osirix', ativo: snMacOs && rootState.login.setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'Osirix Open', acao: 'osirix-open', ativo: snMacOs && rootState.login.setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'Osirix Click', acao: 'osirix-onclick', ativo: snMacOs && rootState.login.setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Java)', acao: 'weasis', ativo: rootState.login.setup.sn_agenda_dicom },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Web)', acao: 'ohif', ativo: rootState.login.setup.sn_agenda_dicom },
        { icone: 'mdi-image-area-close', texto: 'Jpeg', acao: 'jpeg', ativo: rootState.login.setup.sn_agenda_imagem }
      ].filter(v => v.ativo)
      // console.log(state.viewers)
    },
    downloadRadiant ({ commit }, payload) {
      api().post('se1/doJsonRadiant', payload,
        {
          responseType: 'blob',
          timeout: 10000
        }).then(response => {
        saveAs(response.data, payload.cd_atendimento + '.odb')
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    urlOhif ({ rootState }, payload) {
      const url = rootState.login.setup.ds_dicomvix_ohif ? rootState.login.setup.ds_dicomvix_ohif : 'https://pacsviewer.clinux.com.br/local/?json='
      const cgi = rootState.login.setup.sn_dicomvix_ohif ? 'doJsonDownload' : 'doDicomOhif'      
      return api().post('se1/doGetToken', { cd_atendimento: payload.cd_atendimento, cd_exame: payload.cd_exame }).then(response => {
        return `${url}${rootState.cgi.cliente.url}/se1/${cgi}?token=${response.data[0].token}` 
      })
    },
    keyImage ({ }, payload) {
      return api().post('se1/doKeyImage', { cd_exame: payload }).then(response => {
        return response
      })
    },
    visualizadorDicom ({ rootState, dispatch, commit, state }, payload) {
      const displayStudy = 'osirix://?methodName=displayStudy&'
      switch (payload) {
        case 'jpeg':
          dispatch('doDicomSerie')
          break
        case 'radiant':
          const atendimento = [...new Set(rootState.dicomvix.examesDownload.map(i => i.nr_controle))].join()
          const exame = [...new Set(rootState.dicomvix.examesDownload.map(i => i.cd_exame))].join()
          dispatch('downloadRadiant', { cd_atendimento: atendimento || rootState.laudo.atendimento.cd_atendimento, cd_exame: exame || rootState.laudo.atendimento.cd_exame })
          break
        case 'ohif':
          dispatch('urlOhif', rootState.laudo.atendimento).then(response => {
            window.open(response, '_blank')
          })
          break
        case 'weasis':
          ApiLaudo.dicomWeasis(rootState.laudo.atendimento).then(response => {
            if (response.data?.error) {
              commit('layout/SET_ALERTA', { mensagem: response.data.error }, { root: true })
            } else if (response.data?.arquivo) {
              const arquivo = response.data.arquivo
              commit('SET_MODAL_AVISO_JAVA', true)
              if (arquivo.toLowerCase().includes('http')) {
                window.open(arquivo, '_blank', 'toolbar=0,location=0,menubar=0')
              } else {
                window.open(arquivo, '_self')
              }
            }
          })
          break
        case 'osirix':
          window.open(state.urlCloseWindowOsirix, '_blank')
          ApiLaudo.dicomWado(rootState.laudo.atendimento).then(response => {
            const downloadURL = `osirix://?methodName=downloadURL&display=true&URL=${rootState.cgi.cliente.tmp}/${response.data[0].url}`
            window.open(downloadURL, '_blank')
          })
          break
        case 'osirix-open':
          window.open(state.urlCloseWindowOsirix, '_blank')
          ApiLaudo.dicomWado(rootState.laudo.atendimento).then(response => {
            const downloadURL = `osirix://?methodName=downloadURL&display=true&URL=${rootState.cgi.cliente.tmp}/${response.data[0].url}`
            window.open(downloadURL, '_blank')
            ApiLaudo.dicomOsirix(rootState.laudo.atendimento).then(response => {
              if (response.data?.length && response.data[0].Sucesso) {
                setTimeout(() => {
                  window.open(displayStudy + response.data[0].Sucesso, '_blank')
                }, 5000)
              } else {
                commit('layout/SET_ALERTA', { mensagem: 'Erro ao abrir imagem' }, { root: true })
              }
            })
          })
          break
        case 'osirix-onclick':
          window.open(state.urlCloseWindowOsirix, '_blank')
          ApiLaudo.dicomOsirix(rootState.laudo.atendimento).then(response => {
            if (response.data.length && response.data[0].Sucesso) {
              window.open(displayStudy + response.data[0].Sucesso, '_blank')
            } else {
              commit('layout/SET_ALERTA', { mensagem: 'Erro ao abrir imagem' }, { root: true })
            }
          })
          break
      }
    },
    doDicomSerie ({ commit, rootState }) {
      commit('SET_LISTA_JPEG', [])
      api().post('se1/doDicomSerie', {
        cd_atendimento: rootState.laudo.atendimento.cd_atendimento,
        nr_controle: rootState.laudo.atendimento.nr_controle,
        cd_exame: rootState.laudo.atendimento.cd_exame
      }).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        } else if (response.data?.length) {
          commit('SET_LISTA_JPEG', groupBy(response.data.filter(item => { return item.ds_jpg }), 'nomefilme'))
        } else {
          commit('layout/SET_ALERTA', { mensagem: 'Este exame não possui imagens Jpeg !', tipo: 'warning' }, { root: true })
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_LISTA_JPEG (state, payload) {
      state.listaJpeg = payload
      state.modalJpeg = Object.keys(payload).length > 0
    },
    SET_MODAL_AVISO_JAVA (state, payload) {
      if (!state.snAvisoJava) {
        state.modalAvisoJava = false
        state.snAvisoJava = true
      }
    }
  }
}
