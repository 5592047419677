import moment from 'moment'
import consts from './consts'
import Axios from 'axios'
import saveAs from 'file-saver'

export function SetParam (params) {
  var formData = new FormData()
  for (var key in params) {
    if ({}.toString.call(params[key]) !== '[object Function]') {
      formData.append(key, params[key])
    }
  }
  return formData
}

export function tipoImagem (imagem) {
  if (imagem) {
    switch (imagem.charAt(0)) {
      case 'i':
        return 'image/png'
      case 'R':
        return 'image/gif'
      case 'U':
        return 'image/webp'
      case '/':
      default:
        return 'image/jpeg'
    }
  } else {
    return ''
  }
}

export function isDate (data) {
  return !data || moment(data, consts.formatDate, true).isValid()
}

export function toReal (valor) {
  return valor ? valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }) : ''
}

export function validarCpf (strCPF) {
  var soma
  var resto
  soma = 0
  if (strCPF === '00000000000') return false

  for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11

  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(strCPF.substring(9, 10))) return false

  soma = 0
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11

  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(strCPF.substring(10, 11))) return false
  return true
}

export function txt2Pdf (bbTxt) {
  const formData = new FormData()
  const url = 'https://editor.clinux.com.br/www'
  // const url = 'http://localhost:8082/www'
  formData.append('bb_txt', btoa(bbTxt))
  return Axios.post(url + '/doTxt2Pdf', formData, {
    responseType: 'blob',
    timeout: 10000
  }).catch(e => console.error(e))
}

export function favicon (bbIcon) {
  const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link')
  favicon.href = bbIcon
  favicon.version = (new Date()).getTime()
  document.getElementsByTagName('head')[0].appendChild(favicon)
}

export function downloadBase64 (bbArquivo, nome, type = 'application/pdf') {
  const byteCharacters = atob(bbArquivo)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const arquivo = new Blob([byteArray], { type })
  saveAs(arquivo, nome)
}

export function getMime (dataurl) {
  return dataurl.split(',')[0].match(/:(.*?);/)[1]
}

export function blobToDataURL(blob, callback) {
  var a = new FileReader();
  a.onload = function(e) {callback(e.target.result);}
  a.readAsDataURL(blob);
}

export function dataURLtoBlob (dataurl) {
  // https://stackoverflow.com/a/30407959
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}
