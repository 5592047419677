import api from '@/services/api'

export default {
  namespaced: true,
  state: {
    atendimento: 0,
    listaAnexoExterno: [],
    countAnexoExterno: 0,
    porcentagem: 0,
    dsArquivo: '',
    modo: ''
  },
  mutations: {
    SET_LISTA_EXTERNO (state, payload) {
      state.listaAnexoExterno = payload.lista
      state.atendimento = payload.key
    },
    SET_COUNT_EXTERNO (state, payload) {
      state.countAnexoExterno = payload
    },
    SET_PORCENTAGEM (state, payload) {
      state.porcentagem = payload
    },
    SET_DS_ARQUIVO (state, payload) {
      state.dsArquivo = payload
    },
    SET_MODO (state, payload) {
      state.modo = payload
    },
    RESET_PROGRESSO (state) {
      state.porcentagem = 0
      state.dsArquivo = ''
      state.modo = ''
    }
  },
  actions: {
    doLaudoExternoLista ({ commit }, exame) {
      api().post('se1/doLaudoExternoLista', { cd_exame: exame }).then(response => {
        commit('SET_LISTA_EXTERNO', { lista: response.data, key: exame })
        commit('SET_COUNT_EXTERNO', response.data?.length)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoExternoGravar ({ commit }, documento) {
      return api().post('se1/doLaudoExternoGravar', documento,
        {
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            commit('SET_DS_ARQUIVO', documento.ds_arquivo)
            commit('SET_MODO', 'Upload')
            commit('SET_PORCENTAGEM', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
          }
        }).then(response => {
        commit('RESET_PROGRESSO')
        return response
      }).catch(e => {
        commit('RESET_PROGRESSO')
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  }
}
