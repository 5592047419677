import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    listaChat: []
  },
  getters: {
    getField
  },
  actions: {

    atualizarComplemento (context, payload) {
      return new Promise((resolve, reject) => {
        const mensagem = btoa(payload.mensagem)
        api().post('se1/doComplementoFim', { cd_atendimento: payload.atendimento, bb_complemento: mensagem }).then(response => {
          resolve(response)
        })
      })
    },
    carregarChat ({ commit }, payload) {
      api().post('se1/doChatLista', { cd_atendimento: payload.cd_atendimento, sn_medico: payload.sn_medico }).then(response => {
        commit('SET_LISTA_COMENTARIO', response.data)
      })
    },
    apagarChat (context, payload) {
      api().post('se1/doChatApagar', { cd_codigo: payload })
    },
    gravarChat (context, payload) {
      const objVazio = {
        cd_atendimento: payload.atendimento,
        ds_mensagem: payload.mensagem
      }
      return new Promise((resolve, reject) => {
        api().post('se1/doChatGravar', { js_tabela: btoa(JSON.stringify(objVazio)) }).then(response => {
          resolve(response)
        })
      })
    }
  },
  mutations: {
    updateField,
    SET_LISTA_COMENTARIO (state, payload) {
      state.listaChat = payload
    },
    RESET_COMENTARIO (state) {
      state.listaChat = []
    }
  }
}
