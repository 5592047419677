import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'

export default {
  namespaced: true,
  state: {
    listaEmpresa: myLocalStorage.getItem('listaEmpresa') ? JSON.parse(myLocalStorage.getItem('listaEmpresa')) : [],
    listaMedico: myLocalStorage.getItem('listaMedico') ? JSON.parse(myLocalStorage.getItem('listaMedico')) : [],
    listaModalidade: myLocalStorage.getItem('listaModalidade') ? JSON.parse(myLocalStorage.getItem('listaModalidade')) : [],
    listaPlano: myLocalStorage.getItem('listaPlano') ? JSON.parse(myLocalStorage.getItem('listaPlano')) : [],
    listaProcedimento: myLocalStorage.getItem('listaProcedimento') ? JSON.parse(myLocalStorage.getItem('listaProcedimento')) : [],
    listaHorario: myLocalStorage.getItem('listaHorario') ? JSON.parse(myLocalStorage.getItem('listaHorario')) : [],
    listaAtendimento: [],
    listaAgendamento: [],
    listaQuestionario: [],
    listaPesquisa: [],
    listaLaudo: [],
    listaSubPlano: [],
    listaExame: [],
    listaSolicitante: [],
    infoAgendaTermo: '',
    infoAgendaPlano: '',
    infoAgendaModal: '',
    infoAgendaAnexo: '',
    infoAgendaPreparo: '',
    guiaAgendaPlano: false,
    notaFiscal: {},
    legenda: {},
    particular: false,
    etapa: myLocalStorage.getItem('etapa') ? parseInt(myLocalStorage.getItem('etapa')) : 1,
    medico: myLocalStorage.getItem('medico') ? JSON.parse(myLocalStorage.getItem('medico')) : {},
    empresa: myLocalStorage.getItem('unidade') ? JSON.parse(myLocalStorage.getItem('unidade')) : {},
    horario: myLocalStorage.getItem('horario') ? JSON.parse(myLocalStorage.getItem('horario')) : {},
    filtro: myLocalStorage.getItem('filtro') ? JSON.parse(myLocalStorage.getItem('filtro')) : {}
  },
  getters: {
    getField
  },
  actions: {
    doPlanoAviso ({ commit }, payload) {
      return api().post('se1/doPlanoAviso', { cd_plano: payload }).then(response => {
        commit('SET_INFO_PLANO', response.data?.length ? response.data[0] : '')
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doPlanoGuia ({ commit }, payload) {
      return api().post('se1/doPlanoGuia', { js_exame: btoa(JSON.stringify(payload)) }).then(response => {
        commit('SET_GUIA_PLANO', response.data?.length ? response.data[0] : '')
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doModalidadeAviso ({ commit }, payload) {
      api().post('se1/doModalidadeAviso', { cd_modalidade: payload }).then(response => {
        commit('SET_INFO_MODAL', response.data[0]?.bb_infoweb)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAnexoAviso ({ commit }, payload) {
      api().post('se1/doAnexoAviso', { cd_empresa: payload }).then(response => {
        commit('SET_INFO_ANEXO', response.data[0].bb_anexo)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaEmpresa ({ commit }, payload) {
      return api().post('se1/doListaEmpresa', payload).then(response => {
        commit('SET_LISTA_EMPRESA', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaEmpresaExame ({ commit }, payload) {
      return api().post('se1/doListaEmpresaExame', payload).then(response => {
        commit('SET_LISTA_EMPRESA', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaModalidade ({ commit }, payload) {
      return api().post('se1/doListaModalidade', payload).then(response => {
        commit('SET_LISTA_MODALIDADE', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaMedico ({ commit }, payload) {
      return api().post('se1/doListaMedico', payload).then(response => {
        commit('SET_LISTA_MEDICO', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaPlano ({ commit }, payload) {
      api().post('se1/doListaPlano', payload).then(response => {
        commit('SET_LISTA_PLANO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaSubPlano ({ commit }, payload) {
      return api().post('se1/doListaSubPlano', { cd_plano: payload }).then(response => {
        commit('SET_LISTA_SUBPLANO', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaProcedimento ({ commit }, payload) {
      return api().post('se1/doListaProcedimento', payload).then(response => {
        commit('SET_LISTA_PROCEDIMENTO', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaAtendimento ({ commit }, payload) {
      return api().post('se1/doListaAtendimento', { cd_paciente: payload }).then(response => {
        return commit('SET_LISTA_ATENDIMENTO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaAgendamento ({ commit }, payload) {
      return api().post('se1/doListaAgendamento', { cd_paciente: payload }).then(response => {
        return commit('SET_LISTA_AGENDAMENTO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaQuestionario ({ commit }, payload) {
      api().post('se1/doAgendaPergunta', { cd_modalidade: payload }).then(response => {
        commit('SET_LISTA_QUESTIONARIO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doPesquisaAtendimento ({ commit }, payload) {
      return api().post('se1/doPesquisaAtendimento', { cd_atendimento: payload }).then(response => {
        commit('SET_LISTA_PESQUISA', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doPesquisaCadastro ({ commit }, payload) {
      api().post('se1/doPesquisaCadastro', payload).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0].Falha }, { root: true })
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doPesquisaReclamacao ({ commit }, payload) {
      const obj = {
        ds_tabela: 'atendimentos',
        cd_codigo: payload.cd_atendimento,
        cd_operacao: 1,
        js_tabela: btoa(JSON.stringify(payload))
      }
      return api().post('se1/doCadastrar', obj).then(response => {
        if (response.data[0]?.Sucesso || response.data.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: 'Mensagem enviada com sucesso !', tipo: 'success' }, { root: true })
          return true
        } else if (response.data?.length) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
          return false
        } else {
          commit('layout/SET_ALERTA', { mensagem: 'Erro na gravação !' }, { root: true })
          return false
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaLegenda ({ commit }, payload) {
      api().post('se1/doAgendaLegenda', { cd_paciente: payload }).then(response => {
        commit('SET_LEGENDA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaNotaFiscal ({ commit }, payload) {
      api().post('se1/doAgendaNotaFiscal', { cd_atendimento: payload.cd_atendimento, cd_lancamento: payload.cd_lancamento }).then(response => {
        if (response.data.includes('EXCEPTION')) {
          commit('layout/SET_ALERTA', { mensagem: response.data }, { root: true })
        } else {
          window.open(response.data.replace(/.pdf(.*)/mi, '.pdf'), '_blank')
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaSemanal ({ commit }, payload) {
      return api().post('se1/doAgendaSemanal', payload).then(response => {
        if (response.data[0]?.Erro) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro }, { root: true })
        } else if (response.data?.length) {
          commit('SET_LISTA_AGENDA', response.data)
        } else {
          commit('layout/SET_ALERTA', { mensagem: 'Nenhum horário disponível essa semana !', tipo: 'warning' }, { root: true })
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaHorario ({ commit }, payload) {
      return api().post('se1/doAgendaHorario', payload).then(response => {
        commit('SET_HORARIO', response.data.length ? response.data[0] : {})
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaExame ({ commit }, payload) {
      return api().post('se1/doAgendaExame', { cd_atendimento: payload }).then(response => {
        commit('SET_LISTA_EXAME', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaPost ({ commit, state }, payload) {
      return api().post('se1/doAgendaPost', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doListaMedicoSol ({ commit }, payload) {
      const filtro = {}
      if (String(payload).match(/^\d+$/gmi)) { // Apenas números
        filtro.ds_crm = payload
      } else {
        filtro.ds_medico = payload
      }
      return api().post('se1/doListaMedicoSol', filtro).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0].Falha }, { root: true })
        } else {
          commit('SET_LISTA_SOLICITANTE', response.data)
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaEmail ({ commit }, payload) {
      return api().post('se1/doAgendaEmail', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaTermo ({ commit }, payload) {
      return api().post('se1/doAgendaTermo', { cd_empresa: payload }).then(response => {
        commit('SET_TERMO', response.data[0].bb_termo)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaDelete ({ commit }, payload) {
      api().post('se1/doAgendaDelete', { cd_atendimento: payload }).then(response => {
        if (response.data[0]?.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Sucesso, tipo: 'success' }, { root: true })
        } else {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoExternoLista ({ commit }, payload) {
      api().post('se1/doLaudoExternoLista', { cd_exame: payload.cd_exame }).then(response => {
        commit('SET_LAUDO_LISTA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doProcedimentoPreparo ({ commit }, payload) {
      api().post('se1/doProcedimentoPreparo', { cd_procedimento: payload }).then(response => {
        commit('SET_PROCEDIMENTO_PREPARO', response.data[0].bb_preparo)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doProcedimentoTempo ({ commit }, payload) {
      api().post('se1/doProcedimentoTempo', payload).then(response => {
        commit('SET_PROCEDIMENTO_TEMPO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doProcedimentoValor ({ commit }, payload) {
      return api().post('se1/doProcedimentoValor', payload).then(response => {
        return response.data?.length ? response.data[0].nr_vl_particular : '0'
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaConfirmar ({ commit }, payload) {
      return api().post('se1/doAgendaConfirmar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAgendaCancelar ({ commit }, payload) {
      return api().post('se1/doAgendaCancelar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doEmpresaParticular ({ commit }, payload) {
      const arg = {
        ds_tabela: 'empresas',
        cd_codigo: payload,
        cd_operacao: 3,
        js_tabela: btoa(JSON.stringify({ cd_plano_particular: 0 }))
      }
      // return api().post('se1/doSQLExec', { sql: `select cd_plano_particular from empresas where cd_empresa = ${payload}` }).then(response => {
      return api().post('se1/doCadastro', arg).then(response => {
        return response.data[0].cd_plano_particular
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    getAvailabilities ({ commit }, payload) {
      return api().post('se1/getAvailabilities', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  mutations: {
    updateField,
    RESET_FILTRO (state, paciente) {
      state.etapa = 1
      state.empresa = {}
      state.horario = {}
      state.filtro = {
        cd_horario: 0,
        cd_atendimento: 0,
        cd_paciente: paciente,
        cd_empresa: 0,
        cd_plano: 0,
        cd_subplano: 0,
        dt_data: null,
        dt_hora: null,
        dt_hora_fim: null,
        sn_consulta: false,
        sl_exame: []
      }
      state.hora = [7, 22]
      state.particular = false
      myLocalStorage.removeItem('medico')
      myLocalStorage.removeItem('unidade')
      myLocalStorage.removeItem('horario')
      myLocalStorage.removeItem('filtro')
    },
    RESET_MODALIDADE (state) {
      state.listaHorario = []
      state.listaProcedimento = []
      state.listaQuestionario = []
      state.respostas = []
      state.infoAgendaModal = ''
      state.infoAgendaPlano = ''
      state.infoAgendaAnexo = ''
    },
    SET_INFO_PLANO (state, payload) {
      state.infoAgendaPlano = payload?.ds_infoweb ? atob(payload.ds_infoweb) : ''
    },
    SET_GUIA_PLANO (state, payload) {
      state.guiaAgendaPlano = payload ? payload.sn_infoweb : false
    },
    SET_INFO_MODAL (state, payload) {
      state.infoAgendaModal = payload ? atob(payload) : ''
    },
    SET_INFO_ANEXO (state, payload) {
      state.infoAgendaAnexo = payload ? atob(payload) : ''
    },
    SET_LISTA_EMPRESA (state, payload) {
      state.listaEmpresa = payload
      myLocalStorage.setItem('listaEmpresa', JSON.stringify(payload))
    },
    SET_PROCEDIMENTO_PREPARO (state, payload) {
      state.infoAgendaPreparo = payload ? atob(payload) : 'O procedimento não possui preparo !'
    },
    SET_LISTA_MODALIDADE (state, payload) {
      state.listaModalidade = payload
      myLocalStorage.setItem('listaModalidade', JSON.stringify(payload))
    },
    SET_LISTA_PLANO (state, payload) {
      state.listaPlano = payload
      myLocalStorage.setItem('listaPlano', JSON.stringify(payload))
    },
    SET_LISTA_SUBPLANO (state, payload) {
      state.listaSubPlano = payload
    },
    SET_LISTA_MEDICO (state, payload) {
      state.listaMedico = payload
      myLocalStorage.setItem('listaMedico', JSON.stringify(payload))
    },
    SET_LISTA_PROCEDIMENTO (state, payload) {
      state.listaProcedimento = payload
      myLocalStorage.setItem('listaProcedimento', JSON.stringify(payload))
    },
    SET_LISTA_AGENDA (state, payload) {
      state.listaHorario = payload
      myLocalStorage.setItem('listaHorario', JSON.stringify(payload))
    },
    SET_LISTA_AGENDAMENTO (state, payload) {
      state.listaAgendamento = payload
    },
    SET_LISTA_ATENDIMENTO (state, payload) {
      state.listaAtendimento = payload
    },
    SET_LISTA_QUESTIONARIO (state, payload) {
      state.listaQuestionario = payload
    },
    SET_LISTA_PESQUISA (state, payload) {
      state.listaPesquisa = payload
    },
    SET_LISTA_LAUDO (state, payload) {
      state.listaLaudo = payload
    },
    SET_LISTA_SOLICITANTE (state, payload) {
      state.listaSolicitante = payload
    },
    SET_LEGENDA (state, payload) {
      state.legenda = payload
    },
    SET_NOTAFISCAL (state, payload) {
      state.notaFiscal = payload
    },
    SET_HORARIO (state, payload) {
      state.horario = payload
      myLocalStorage.setItem('horario', JSON.stringify(payload))
    },
    SET_EMPRESA (state, payload) {
      state.empresa = payload
      myLocalStorage.setItem('unidade', JSON.stringify(payload))
    },
    SET_MEDICO (state, payload) {
      state.medico = payload
      myLocalStorage.setItem('medico', JSON.stringify(payload))
    },
    SET_AGENDA_MEDICO (state, payload) {
      state.medico = payload
      myLocalStorage.setItem('medico', JSON.stringify(payload))
    },
    SET_LISTA_EXAME (state, payload) {
      state.listaExame = payload
    },
    SET_TERMO (state, payload) {
      state.infoAgendaTermo = payload ? atob(payload) : ''
    }
  }
}
