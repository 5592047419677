import api from '@/services/api'
import { saveAs } from 'file-saver'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    atendimento: 0,
    listaAnexo: [],
    countAnexo: 0,
    porcentagem: 0,
    dsArquivo: '',
    modo: ''
  },
  mutations: {
    DEL_LISTA (state, payload) {
      state.listaAnexo.forEach((obj, index) => {
        if (obj.cd_documento === payload) {
          state.listaAnexo.splice(index, 1)
        }
      })
    },
    ADD_LISTA (state, payload) {
      state.listaAnexo.push(payload)
    },
    SET_LISTA (state, payload) {
      state.listaAnexo = payload.lista
      state.atendimento = payload.key
    },
    SET_COUNT (state, payload) {
      state.countAnexo = payload
    },
    SET_PORCENTAGEM (state, payload) {
      state.porcentagem = payload
    },
    SET_DS_ARQUIVO (state, payload) {
      state.dsArquivo = payload
    },
    SET_MODO (state, payload) {
      state.modo = payload
    },
    RESET_PROGRESSO (state) {
      state.porcentagem = 0
      state.dsArquivo = ''
      state.modo = ''
    },
    RESET_ANEXO (state) {
      state.atendimento = 0
      state.listaAnexo = []
      state.countAnexo = 0
    }
  },
  actions: {
    carregarAnexo ({ commit }, atendimento) {
      api().post('se1/doLaudoFiltroScanner', { cd_atendimento: atendimento }).then(response => {
        commit('SET_LISTA', { lista: response.data, key: atendimento })
        commit('SET_COUNT', response.data?.length)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    gravarAnexo ({ commit }, documento) {
      return api().post('se1/doAnexoSalvar', documento, {
        timeout: 0,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          commit('SET_DS_ARQUIVO', documento.ds_arquivo)
          commit('SET_MODO', 'Upload')
          commit('SET_PORCENTAGEM', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }
      }).then(response => {
        commit('RESET_PROGRESSO')
        return response
      }).catch(e => {
        commit('RESET_PROGRESSO')
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAnexoDownload ({ commit }, documento) {
      api().post('www/doAnexoDownload', {
        cd_documento: documento.cd_documento
      }, {
        responseType: 'blob',
        timeout: 0,
        onDownloadProgress: (progressEvent) => {
          commit('SET_MODO', 'Download')
          commit('SET_DS_ARQUIVO', documento.ds_arquivo)
          commit('SET_PORCENTAGEM', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }
      }).then(response => {
        commit('RESET_PROGRESSO')
        const ext = response.data.type.split('/')[1]
        const doc = ['jpeg', 'jpg'].includes(ext.toLowerCase()) || !documento.ds_arquivo ? `${documento.ds_documento || documento.cd_documento}.jpeg` : documento.ds_arquivo
        saveAs(response.data, doc)
      }).catch(e => {
        commit('RESET_PROGRESSO')
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    urlAnexo (context, documento) {
      const params = {
        cd_documento: documento.cd_documento,
        token: store.getters['login/token']
      }
      const url = store.state.cgi.cliente.url.toLowerCase()
      if ((url.includes('https') || url.includes('localhost'))) {
        return Promise.resolve(`${api().defaults.baseURL}/www/doAnexoDownload?${new URLSearchParams(params).toString()}`)
      } else {
        const urlParams = JSON.stringify(params)
        return Promise.resolve(`${api().defaults.baseURL}/www/doAnexoDownload?params=${urlParams}&endpoint=${store.state.cgi.cliente.url}/www/doAnexoDownload`)
      }
    },
    apagarAnexo ({ commit }, documento) {
      const arg = {
        ds_tabela: 'atendimentos_documentos',
        cd_codigo: documento,
        cd_operacao: 2
      }
      return api().post('se1/doCadastro', arg)
        .then(response => {
          commit('DEL_LISTA', documento)
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
    }
  }
}
