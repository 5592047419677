import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import * as utils from '@/mixins/utils'
import { myLocalStorage } from '@/mixins/localstore'

const filtroInicial = {
  cd_atendimento: '',
  cd_paciente: '',
  cd_data: 1,
  cd_fila: 0,
  cd_status: -1,
  cd_empresa: 0,
  cd_modalidade: 0,
  cd_executante: 0,
  cd_urgente: 0,
  cd_aviso: 0,
  cd_complemento: 0,
  dt_de: moment().subtract(3, 'months').format('DD/MM/YYYY'),
  dt_ate: moment().format('DD/MM/YYYY'),
  nr_assinado: 2,
  nr_texto: 0,
  nr_imagem: 0,
  nr_audio: 0,
  nr_controle: '',
  flag: null,
  sn_download: false,
}

export default {
  namespaced: true,
  state: {
    grid: [],
    dicionario: [],
    Grid: {
      gridOrder: myLocalStorage.getItem('gridOrder')
        ? JSON.parse(myLocalStorage.getItem('gridOrder'))
        : ['ds_urgente', 'ts_entrega'],

      gridSortDesc: myLocalStorage.getItem('gridSortDesc')
        ? JSON.parse(myLocalStorage.getItem('gridSortDesc'))
        : [true, false],

      gridLines: parseInt(myLocalStorage.getItem('gridLines') || 10)
    },
    timer: null,
    filtro: myLocalStorage.getItem('filtro')
      ? JSON.parse(myLocalStorage.getItem('filtro'))
      : Object.assign({}, filtroInicial),

    examesDownload: [],
    listas: {
      status: [],
      modalidades: [],
      medicos: [],
      empresas: [],
      avisos: [],
      urgentes: [],
      complementos: [],
      filas: [],
      datas: [],
      snTodos: [{ nr: 0, ds: 'TODOS' }, { nr: 1, ds: 'SIM' }, { nr: 2, ds: 'NÃO' }]
    },
    permissao: myLocalStorage.getItem('permissao') ? JSON.parse(myLocalStorage.getItem('permissao')) : { sn_gerente: true, sn_executante: true, sn_revisor: true },
  },
  getters: {
    getField
  },
  actions: {
    atualizarGrid ({ state, commit }, filtroExterno = undefined) {
      let filtroAtual = state.filtro
      if (filtroExterno)
        filtroAtual = Object.assign(state.filtro, filtroExterno)

      if (utils.isDate(filtroAtual.dt_de) && utils.isDate(filtroAtual.dt_ate)) {
        api().post('se1/doLaudoLista', filtroAtual).then(response => {
          if (response?.data?.length) {
            commit('SET_GRID_LISTA', response.data || [])
          } else {
            commit('SET_GRID_LISTA', [])
          }
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
      }
    },
    carregarFiltroSimples ({ commit }, payload) {
      api().post('se1/doLaudoFiltroStatus').then(response => {
        commit('SET_FILTRO_STATUS', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      api().post('se1/doLaudoFiltroData').then(response => {
        commit('SET_FILTRO_DATA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      api().post('se1/doLaudoFiltroModalidade', { cd_medico: payload.cd_medico }).then(response => {
        commit('SET_FILTRO_MODALIDADE', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      api().post('se1/doLaudoFiltroFila', { cd_medico: payload.cd_medico }).then(response => {
        commit('SET_FILTRO_FILA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    carregarFiltroAvancado ({ commit }, payload) {
      api().post('se1/doLaudoFiltroEmpresa').then(response => {
        commit('SET_FILTRO_EMPRESA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      api().post('se1/doLaudoFiltroMedico').then(response => {
        commit('SET_FILTRO_MEDICO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      api().post('se1/doLaudoFiltroUrgente').then(response => {
        commit('SET_FILTRO_URGENTE', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      api().post('se1/doLaudoFiltroAviso').then(response => {
        commit('SET_FILTRO_AVISO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      api().post('se1/doComplementoLista').then(response => {
        commit('SET_FILTRO_COMPLEMENTO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doCadastrar ({ commit }, payload) {
      const op = payload.cd_operacao
      payload.cd_operacao = undefined

      const obj = {
        ds_tabela: 'medicos_dicionario',
        cd_operacao: op,
        cd_codigo: payload.cd_codigo,
        js_tabela: btoa(JSON.stringify(payload))
      }
      return api().post('se1/doCadastrar', obj).then(response => {
        if (response.data[0]?.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: 'Adicionado com sucesso !', tipo: 'success' }, { root: true })
        } else {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        }
        return true
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doDeletarDicionario ({ commit }, payload) {
      const obj = {
        ds_tabela: 'medicos_dicionario',
        cd_operacao: 2,
        cd_codigo: payload.cd_codigo
      }
      return api().post('se1/doCadastrar', obj).then(response => {
        if (response.data[0]?.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: 'Deletado com sucesso !', tipo: 'success' }, { root: true })
        } else {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        }
        return true
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doMedicoDicionario ({ commit }, payload) {
      api().post('se1/doMedicoDicionario', payload).then(response => {
        commit('SET_LISTA_DICIONARIO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoPermissao ({ commit }) {
      return api().post('se1/doLaudoPermissao').then(response => {
        commit('SET_LAUDO_PERMISSAO', response.data[0])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  mutations: {
    updateField,
    SET_FILTRO_DATA (state, payload) {
      state.listas.datas = payload
    },
    SET_LISTA_DICIONARIO (state, payload) {
      state.dicionario = payload
    },
    SET_GRID_LISTA (state, payload) {
      state.timer = moment()
      state.grid = payload
    },
    SET_FILTRO_STATUS (state, payload) {
      state.listas.status = payload
    },
    SET_FILTRO_FILA (state, payload) {
      state.listas.filas = payload
    },
    SET_FILTRO_EMPRESA (state, payload) {
      state.listas.empresas = payload
    },
    SET_FILTRO_MODALIDADE (state, payload) {
      state.listas.modalidades = payload
    },
    SET_FILTRO_MEDICO (state, payload) {
      state.listas.medicos = payload
    },
    SET_FILTRO_URGENTE (state, payload) {
      state.listas.urgentes = payload
    },
    SET_FILTRO_AVISO (state, payload) {
      state.listas.avisos = payload
    },
    SET_FILTRO_COMPLEMENTO (state, payload) {
      state.listas.complementos = payload
    },
    SET_FILTRO_CONFIG (state, payload) {
      state.Grid.gridOrder = payload.sortBy
      state.Grid.gridSortDesc = payload.sortDesc
      state.Grid.gridLines = payload.itemsPerPage
    },
    SET_FILTRO (state, payload) {
      state.filtro = payload
      myLocalStorage.setItem('filtro', JSON.stringify(payload))
    },
    RESET_DOWNLOAD (state) {
      state.examesDownload = []
    },
    RESET_FILTRO (state, payload = false) {
      state.filtro = Object.assign({}, filtroInicial)
      state.Grid.gridOrder = ['ds_urgente', 'ts_entrega']
      state.Grid.gridSortDesc = [true, false]
      state.Grid.gridLines = 10
      if (payload) {
        state.filtro.nr_assinado = 0
        state.filtro.dt_de = moment().format('DD/MM/YYYY')
        state.filtro.dt_ate = ''
      }
      state.filtro.flag = true
    },
    SET_PERIODO (state, payload) {
      const data = moment().subtract(payload, 'days').format('DD/MM/YYYY')
      const hoje = moment().format('DD/MM/YYYY')

      state.filtro.dt_de = data
      switch (payload) {
        case 0:
          state.filtro.dt_de = hoje
          state.filtro.dt_ate = ''
          break
        case 1:
          state.filtro.dt_de = data
          state.filtro.dt_ate = ''
          break
        case 7:
        default:
          state.filtro.dt_de = data
          state.filtro.dt_ate = hoje
          break
      }
    },
    SET_LAUDO_PERMISSAO (state, payload) {
      state.permissao = payload || { sn_gerente: true, sn_executante: true, sn_revisor: true }
      myLocalStorage.setItem('permissao', JSON.stringify(state.permissao))
    },
    REMOVE_STORAGE (state) {
      state.filtro.flag = null
      myLocalStorage.removeItem('filtro')
    }

  }
}
