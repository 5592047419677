import axios from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'

export default {
  namespaced: true,
  state: {
    editor: null,
    editorSecundario: null,
    sfdt: myLocalStorage.getItem('sfdt'),
    selection: {},
    keyDown: {
      event: {},
      isHandled: false
    }
  },
  actions: {
    bloquearLayout ({ state, rootState, commit }) {
      axios().post('se1/doFuncionarioAcesso', {
        cd_funcionario: rootState.login.user.cd_funcionario,
        ds_form: 'ATE_LAUDO_EXE_CABECALHO'
      }).then(response => {
        commit('SET_BLOQUEIO', response.data.length > 0)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    keyDown ({ state, dispatch }, { event, isHandled }) {
      const key = event.key.toLowerCase()
      state.keyDown = { event, isHandled }

      if ((event.altKey && ['å', 'a'].includes(key)) || key === 'f10') dispatch('iniciarReconhecimento')

      if (key === 'f3') dispatch('iniciarAutoTexto')
    },

    iniciarReconhecimento ({ state, rootState }) {
      state.keyDown.event.preventDefault()
      state.keyDown.isHandled = true
      rootState.voz.inicioPorAtalho = !rootState.voz.inicioPorAtalho
    },

    iniciarAutoTexto ({ state, dispatch }) {
      state.keyDown.event.preventDefault()
      state.keyDown.isHandled = true
      // Recebe palavra selecionada
      state.editor.selection.moveToPreviousCharacter()
      state.editor.selection.selectCurrentWord()
      const texto = state.editor.selection.text
      console.log(texto)
      if (texto) {
        state.editor.search.find(texto, 'WholeWord')
        dispatch('laudo/doLaudoFiltroTexto', texto, { root: true })
      }
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_EDITOR (state, payload) {
      state.editor = payload
    },
    SET_EDITOR_SECUNDARIO (state, payload) {
      state.editorSecundario = payload
    },
    SET_SFDT (state, payload) {
      state.sfdt = payload
    },
    /*
    SET_SELECTION (state, selection) {
      state.selection = {}
      state.selection = selection
    },
    */
    SET_BLOQUEIO (state, payload) {
      if (payload) {
        state.editor.selectionChange = () => {
          // commit('SET_SELECTION', state.editor.selection)
          if (['HeaderText', 'FooterText', 'HeaderTableText'].includes(state.editor.selection.contextType)) {
            state.editor.selection.closeHeaderFooter()
          }
        }
      } else {
        state.editor.selectionChange = () => {
          // commit('SET_SELECTION', state.editor.selection)
          return null
        }
      }
    }
  }
}
