import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    filtro: {
      dt_de: moment().subtract(30, 'days').format('DD/MM/YYYY'),
      dt_ate: moment().format('DD/MM/YYYY'),
      cd_paciente: 0,
      ds_paciente: ''
    },
    listaAtendimento: []
  },
  getters: {
    getField
  },
  actions: {
    doListaSolicitante ({ commit, state, rootState }) {
      state.filtro.cd_medico = rootState.login.user.cd_medico
      api().post('se1/doListaSolicitante', state.filtro).then(response => {
        if (response.data.length && (response.data[0].Erro || response.data[0].Falha)) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0].Falha }, { root: true })
        } else {
          commit('SET_LISTA_ATENDIMENTO', response.data)
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  mutations: {
    updateField,
    SET_LISTA_ATENDIMENTO (state, payload) {
      state.listaAtendimento = payload
    }
  }
}
