import api from '@/services/api'

export default {
  namespaced: true,
  state: {
    listaPlano: []
  },
  getters: {

  },
  actions: {

    abrirPlano (context, payload) {
      return new Promise((resolve, reject) => {
        const objVazio = {
          cd_empresa_plano: 0,
          ds_empresa_plano: '',
          cd_empresa: '',
          cd_funcionario: ''
        }
        api().post('se1/doCadastro', {
          ds_tabela: 'empresas_planos',
          cd_codigo: payload.id,
          cd_operacao: payload.op,
          js_tabela: btoa(JSON.stringify(payload.obj || objVazio))
        }).then(response => {
          response.data = response.data?.length ? response.data : [objVazio]
          resolve(response)
        }).catch(e => {
          reject(e)
        })
      })
    },

    listarPlano ({ commit }, empresa) {
      // api().post('se1/doSQLExec', { sql: `select cd_empresa_plano as cd_plano, ds_empresa_plano as ds_plano from empresas_planos where cd_empresa = ${empresa} order by 2` }).then(response => {
      api().post('se1/doListaEmpresaPlano', { cd_empresa: empresa }).then(response => {
        commit('SET_LISTA', response.data)
      })
    }
  },

  mutations: {
    SET_LISTA (state, payload) {
      state.listaPlano = payload
    }
  }
}
