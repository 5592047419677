import api from '@/services/api'
import ApiLaudo from '@/services/laudo'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    atendimento: {},
    atributo: {},
    prontuario: [],
    snHistorico: false
  },
  actions: {
    doPacienteAtributo ({ commit, state }, payload) {
      return api().post('se1/doPacienteAtributo', {
        cd_atendimento: state.atendimento.cd_atendimento,
        cd_valor: payload
      }).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        } else {
          const attr = response.data[0]?.bb_valor ? atob(response.data[0].bb_valor) : null
          if (attr?.includes('{\\rtf1')) {
            return ApiLaudo.RtfToSfd(response.data[0].bb_valor).then(response => {
              commit('SET_ATRIBUTO', response.data)
              return response.data
            })
          } else {
            commit('SET_ATRIBUTO', {})
            return Promise.resolve(null)
          }
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    doPacienteProntuario ({ commit, state }) {
      return api().post('se1/doPacienteProntuario', {
        cd_atendimento: state.atendimento.cd_atendimento,
        sn_historico: state.snHistorico,
        cd_medico: state.atendimento.cd_medico
      }).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        } else {
          const prontuario = response.data || []
          prontuario.forEach(atributo => {
            atributo.ds_valor = (atributo.ds_valor === null && atributo.bb_valor) ? atob(atributo.bb_valor) : atributo.ds_valor
          })
          commit('SET_PRONTUARIO', response.data || [])
        }
        return response.data
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_ATRIBUTO (state, payload) {
      state.atributo = payload
    },
    SET_ATENDIMENTO (state, payload) {
      state.atendimento = payload
    },
    SET_PRONTUARIO (state, payload) {
      state.prontuario = payload
    }
  }
}
