import moment from 'moment'
import api from '@/services/api'
import * as utils from '@/mixins/utils'
import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'
import { favicon } from '@/mixins/utils'
import { publicIpv4 } from 'public-ip'

export default {
  namespaced: true,
  state: {
    status: '',
    valid: myLocalStorage.getItem('valid') || '',
    loginUrl: myLocalStorage.getItem('loginUrl') || '',
    empresa: myLocalStorage.getItem('empresa') ? JSON.parse(myLocalStorage.getItem('empresa')) : 0,
    setup: myLocalStorage.getItem('setup') ? JSON.parse(myLocalStorage.getItem('setup')) : {},
    user: myLocalStorage.getItem('user') ? JSON.parse(myLocalStorage.getItem('user')) : {},
    logo: myLocalStorage.getItem('logo') ? myLocalStorage.getItem('logo') : '',
    favicon: myLocalStorage.getItem('favicon') ? myLocalStorage.getItem('favicon') : '',
    versao: myLocalStorage.getItem('versao') ? JSON.parse(myLocalStorage.getItem('versao')) : {}
  },
  mutations: {
    updateField,
    auth_request (state) {
      state.status = 'carregando...'
    },
    auth_success (state, user) {
      state.status = 'autenticado'
      state.user = user
      state.valid = moment().format()
      state.loginUrl = window.location.pathname
      myLocalStorage.setItem('user', JSON.stringify(user))
      myLocalStorage.setItem('token', user.ds_token)
      myLocalStorage.setItem('valid', state.valid)
      myLocalStorage.setItem('logo', state.logo)
      myLocalStorage.setItem('favicon', state.favicon)
      myLocalStorage.setItem('loginUrl', state.loginUrl)
    },
    auth_error (state, msg) {
      state.status = msg || 'acesso negado'
      state.user = {}
      myLocalStorage.removeItem('token')
      myLocalStorage.removeItem('user')
      myLocalStorage.removeItem('valid')
    },
    auth_exit (state) {
      state.status = ''
      state.user = {}
      localStorage.clear()
      myLocalStorage.setItem('versao', JSON.stringify(state.versao))
    },
    auth_token (state, tok) {
      state.user.ds_token = tok
      myLocalStorage.setItem('token', tok)
    },
    auth_valid (state, val) {
      state.valid = val
      myLocalStorage.setItem('valid', val)
    },
    SET_LOGO (state, payload) {
      state.logo = payload.imagem
      state.favicon = payload.favicon
      favicon(state.favicon)
    },
    SET_EMPRESA (state, payload) {
      state.empresa = payload
      if (payload) myLocalStorage.setItem('empresa', payload)
    },
    SET_VERSAO (state, val) {
      state.versao = {
        vue: '3069.8',
        cgi: val.nr_clinux,
        banco: val.nr_banco
      }
      myLocalStorage.setItem('versao', JSON.stringify(state.versao))
    },
    SET_SETUP (state, val) {
      state.setup = val
      myLocalStorage.setItem('setup', JSON.stringify(val))
    }
  },
  actions: {
    login ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        api().post('se1/doFuncionarioLogin', user)
          .then(response => {
            if (response.data?.length) {
              if (response.data[0].Erro) {
                commit('auth_error', response.data[0].Erro)
              } else {
                commit('auth_success', response.data[0])
              }
            } else {
              commit('auth_error')
            }
            resolve(response)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_exit')
        resolve()
      })
    },
    portalSetup ({ commit }, payload) {
      commit('SET_EMPRESA', payload)
      return api().post('se1/doPortalSetup', { cd_empresa: payload }).then(
        response => {
          commit('SET_SETUP', response.data[0])
          return response
        })
    },
    exibeLogo ({ commit, state }) {
      if (!state.logo) {
        api().post('se1/doAgendaLogo', { cd_empresa: state.empresa }).then(
          response => {
            if (response.data?.length) {
              let imagem = response.data[0].bb_logo
              let favicon = response.data[0].bb_icon
              if (imagem) imagem = `data:${utils.tipoImagem(imagem)};base64,${imagem}`
              if (favicon) favicon = `data:${utils.tipoImagem(favicon)};base64,${favicon}`
              commit('SET_LOGO', { imagem, favicon })
              commit('SET_VERSAO', response.data[0])
            }
          })
          .catch(e => {
            console.error(e)
          })
      }
    },
    loginMedico ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        publicIpv4().then(ipv4 => {
          user.ip = ipv4
          api().post('se1/doMedicoLogin', user)
            .then(response => {
              if (response.data?.length) {
                if (response.data[0].Erro) {
                  commit('auth_error', response.data[0].Erro)
                } else {
                  commit('auth_success', response.data[0])
                }
              } else {
                commit('auth_error')
              }
              resolve(response)
            })
            .catch(e => {
              commit('auth_error')
              reject(e)
            })
        })
      })
    },
    loginPaciente ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        publicIpv4().then(ipv4 => {
          user.ip = ipv4
          api().post('se1/doPacienteLogin', user)
            .then(response => {
              if (response.data?.length) {
                if (response.data[0].Erro) {
                  commit('auth_error', response.data[0].Erro)
                } else {
                  commit('auth_success', response.data[0])
                }
              } else {
                commit('auth_error')
              }
              resolve(response)
            })
            .catch(err => {
              commit('auth_error')
              reject(err)
            })
        })
      })
    },
    loginResultados ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        publicIpv4().then(ipv4 => {
          user.ip = ipv4
          api().post('se1/doAgendaLogin', user)
            .then(response => {
              if (response.data?.length) {
                if (response.data[0].Erro || response.data[0].Falha) {
                  commit('auth_error', response.data[0].Erro || response.data[0].Falha)
                } else {
                  const paciente = response.data[0]
                  paciente.sn_resultado = true
                  paciente.nr_controle = user.nr_controle
                  commit('auth_success', paciente)
                }
              } else {
                commit('auth_error')
              }
              resolve(response)
            })
            .catch(err => {
              commit('auth_error')
              reject(err)
            })
        })
      })
    },
    loginAnamnese ({ commit, dispatch }, user) {
      return dispatch('login', user).then(response => {
        const funcionario = response.data[0]?.cd_funcionario ? response.data[0] : null
        if (funcionario) {
          const permissoes = {}
          return dispatch('validarPermissoes', {
            cd_funcionario: funcionario.cd_funcionario,
            ds_form: 'GER_FUNCIONARIO_EXE_ENTREVISTA'
          }).then(response => {
            permissoes.entrevista = response.data[0]?.cd_permissao > 0
            return dispatch('validarPermissoes', {
              cd_funcionario: funcionario.cd_funcionario,
              ds_form: 'GER_FUNCIONARIO_EXE_QUESTIONARIO'
            }).then(response => {
              permissoes.editar = response.data[0]?.cd_permissao > 0
              funcionario.permissoes = permissoes
              commit('auth_success', funcionario)
              return funcionario
            })
          })
        } else {
          commit('auth_error', 'Usuário ou senha inválida !')
        }
      }).catch(e => {
        commit('auth_error')
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    loginAnamneseExterno ({ commit }, user) {
      commit('auth_request')
      return api().post('se1/doAnamneseLogin', user)
        .then(response => {
          if (response.data?.length) {
            if (response.data[0].Erro || response.data[0].Falha) {
              commit('auth_error', response.data[0].Erro || response.data[0].Falha)
            } else {
              const usuario = response.data[0]
              usuario.permissoes = { editar: false, entrevista: true }
              commit('auth_success', usuario)
              return usuario
            }
          } else {
            commit('auth_error')
          }
        })
        .catch(err => {
          commit('auth_error', err)
        })
    },
    validarPermissoes ({ commit }, payload) {
      return api().post('se1/doFuncionarioAcesso', {
        cd_funcionario: payload.cd_funcionario,
        ds_form: payload.ds_form
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    loginOtrs ({ commit }, user) {
      return api().post('se1/dotOtrsLogin', user)
        .then(response => {
          if (response.data?.length) {
            if (response.data[0].Erro) {
              commit('auth_error', response.data[0].Erro)
            } else {
              response.data[0].ds_email = user.id
              commit('auth_success', response.data[0])
            }
          } else {
            commit('auth_error')
          }
          return response
        })
        .catch(e => {
          commit('auth_error')
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
    },
    doFuncionarioPassword ({ commit }, payload) {
      return api().post('se1/doFuncionarioPassword', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  getters: {
    getField,
    isMedico: state => !!state.user.cd_medico,
    isPaciente: state => !!state.user.cd_paciente,
    isCliente: state => !!state.user.cd_cliente,
    isLoggedIn: state => !!state.user.ds_token || !!state.user.cd_cliente || !!state.user.cd_fornecedor,
    authStatus: state => state.status,
    token: state => state.user.ds_token,
    currentUser: state => state.user,
    setup: state => state.setup
  }
}
