// import axios from 'axios'
import { myLocalStorage } from '@/mixins/localstore'
// import { getField, updateField } from 'vuex-map-fields'
import ApiLaudo from '@/services/laudo'
// import ApiTools from '@/services/wptools'
import CryptoJS from 'crypto-js'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    urlLaudite: 'https://app.laudite.com.br',
    companyToken: '',
    date: moment().format('YYYYMMDD'),
    viewMode: myLocalStorage.getItem('viewmode'),
    inExecution: myLocalStorage.getItem('laudite'),
    proximo: false,
    existeListener: false,
    iframe: null,
    eventEmit: null,
    url: ''
  },
  actions: {
    makeUrl ({ commit, state, rootState }) {
      const pass = state.companyToken + state.date
      const iv = pass.slice(-16)

      const CipherJS = {
        pass: CryptoJS.enc.Utf8.parse(pass),
        iv: CryptoJS.enc.Utf8.parse(iv),
        encript (msg) {
          try {
            const options = { mode: CryptoJS.mode.CBC, iv: this.iv }
            const json = CryptoJS.AES.encrypt(msg, this.pass, options)
            return json.ciphertext.toString(CryptoJS.enc.Hex)
          } catch (err) {
            console.error(err)
            return ''
          }
        },
        decript (hex) {
          try {
            const options = { mode: CryptoJS.mode.CBC, iv: this.iv }
            const json = CryptoJS.AES.decrypt({
              ciphertext: CryptoJS.enc.Hex.parse(hex)
            }, this.pass, options)
            return json.toString(CryptoJS.enc.Utf8)
          } catch (err) {
            console.error(err)
            return ''
          }
        }
      }
      try {
        console.log(rootState.login.user.ds_email)
        const user = CipherJS.encript(rootState.login.user.ds_email)
        const mask = (state.companyToken === '60dc9862e35cb25c26cdbc92') ? 'both' : 'onlyCompany'
        const url = `${state.urlLaudite}/?company=${state.companyToken}&user=${user}&view=${state.modeView || 'slim'}&masksScope=${mask}`
        console.log(url)
        commit('SET_URL', url)
      } catch (error) {
        commit('layout/SET_ALERTA', { mensagem: 'Para utilizar o editor Laudite, é necessário possuir e-mail no cadastro do médico.' }, { root: true })
      }
    },

    startEvents ({ state, dispatch }) {
      dispatch('makeUrl')
      if (!state.existeListener) dispatch('getData')
    },

    sendData ({ state }, payload) {
      return state.iframe?.contentWindow ? Promise.resolve(state.iframe.contentWindow.postMessage(payload, state.urlLaudite)) : {}
    },

    getData ({ commit, dispatch }) {
      if (window.addEventListener) {
        window.addEventListener('message', e => dispatch('handlePostMessage', e), false)
      } else if (window.attachEvent) { // ie8
        window.attachEvent('onmessage', e => dispatch('handlePostMessage', e))
      }
      commit('SET_LISTENER', true)
    },

    handlePostMessage ({ commit, dispatch }, payload) {
      const actions = ['saveReport', 'endReport', 'nextReport', 'displayMode', 'loaded', 'shortcuts', 'companyMasks', 'sendMask']
      if (payload?.data && actions.includes(payload?.data?.action)) {
        try {
          dispatch(payload.data.action, payload.data.data || null)
        } catch (e) {
          console.error(e)
        }
      }
    },
    statusLaudite ({ state, commit }) {
      commit('SET_IN_EXECUTION', !state.inExecution)
      return Promise.resolve(true)
    },
    htmlToSfdt ({ rootState, dispatch, commit }, payload) {
      // return ApiTools().post('www/doRtf2Rtf', { bb_rtf: window.btoa(((payload))) }, { root: true }).then(rtf => {
      // return ApiLaudo.RtfToSfd(window.btoa(unescape(encodeURIComponent(rtf.data)))).then(response => {
      return ApiLaudo.HtmlToSfd((payload)).then(response => {
        if (rootState.laudo.layout) {
          rootState.editor.editor.openBlank()
          rootState.editor.editor.open(rootState.laudo.layout)
          /*
          if (rootState.laudo.layout?.sections?.length) {
            response.data.sections.forEach(section => {
              section.sectionFormat = rootState.laudo.layout?.sections[0]?.sectionFormat || section.sectionFormat
            })
          }
          */
          rootState.editor.editor.editor.paste(JSON.stringify(response.data))
          rootState.editor.editor.selection.selectAll()
          rootState.editor.editor.selection.characterFormat.fontColor = '#000000'
          return Promise.resolve(true)
        } else {
          commit('layout/SET_ALERTA', { mensagem: 'Não foi possível validar o conteúdo do laudo.', tipo: 'warning' }, { root: true })
          return Promise.resolve(false)
        }
      }).catch(e => {
        console.error(e)
      })
      /*
      }).catch(e => {
        console.error(e)
      })
      */
    },

    saveReport ({ dispatch, commit }, payload) {
      if (payload) {
        dispatch('htmlToSfdt', payload).then(bool => {
          if (bool) commit('SET_EVENT_EMIT', 'salvar')
        })
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'O laudo precisa estar preenchido para continuar.' }, { root: true })
      }
    },

    endReport ({ dispatch, commit }, payload) {
      if (payload) {
        dispatch('htmlToSfdt', payload).then(bool => {
          if (bool) commit('SET_EVENT_EMIT', 'assinar')
        })
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'O laudo precisa estar preenchido para continuar.' }, { root: true })
      }
    },

    nextReport ({ dispatch, commit }, payload) {
      if (payload) {
        dispatch('htmlToSfdt', payload).then(bool => {
          if (bool) commit('SET_EVENT_EMIT', 'proximo')
        })
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'O laudo precisa estar preenchido para continuar.' }, { root: true })
      }
    },

    displayMode ({ commit }, payload) {
      if (payload) {
        commit('SET_VIEW_MODE', payload)
      }

      if (payload === 'hidden') {
        commit('SET_IN_EXECUTION', false)
        window.dispatchEvent(new Event('resize'))
      }
    },

    companyMasks ({ dispatch, rootState }) {
      dispatch('laudo/doModeloLista', { sn_html: true }, { root: true }).then(() => {
        // const modelos = rootState.laudo.listaModelos?.map(modelo => String(modelo.cd_modelo)) || []
        const modelos = rootState.laudo.listaModelos?.map(modelo => Object({ id: modelo.cd_modelo, title: modelo.ds_modelo, html: atob(modelo.bb_html) })) || []
        dispatch('sendData', {
          action: 'companyMasks',
          data: modelos
        })
      })
    },

    loaded ({ commit, dispatch }) {
      const lauditeIfr = document.querySelector('.lauditeIframe')
      // const lauditeIfr = document.querySelector('.lauditeIframe').contentWindow
      try {
        commit('SET_IFRAME', lauditeIfr)
      } catch (err) {
        console.error(err)
      }
      dispatch('sendMask')
    },

    shortcuts ({ commit }, payload) {
      // console.log('shortcuts:', payload)
    },

    sendMask ({ dispatch, commit, rootState }) {
      if (rootState.editor.editor) {
        let documento = JSON.parse(rootState.editor.editor.serialize())
        // let original = JSON.parse(rootState.editor.editor.serialize())
        documento.sections.forEach(section => delete section.headersFooters)
        // original.sections.forEach(section => delete section.blocks)
        documento = JSON.stringify(documento)
        const original = rootState.editor.editor.serialize()
        rootState.editor.editor.open(documento)
        rootState.editor.editor.saveAsBlob('Docx').then(sfdDocument => {
          //ApiLaudo.SfdToRtf(sfdDocument).then(rtfDocumento => {
            //ApiTools().post('www/doRtf2Htm', { bb_rtf: window.btoa(rtfDocumento.data) }, { root: true }).then(response => {
          ApiLaudo.SfdtToHtml(sfdDocument).then(response => {
              dispatch('sendData', {
                action: 'sendMask',
                data: String(response.data)
              }).then(() => {
                rootState.editor.editor.open(original)
                rootState.editor.editor.selection.selectAll()
                rootState.editor.editor.editor.cut()
                commit('laudo/SET_LAYOUT', JSON.parse(rootState.editor.editor.serialize()), { root: true })
              })
            //})
          })
        }).catch(e => {
          console.error(e)
        })
      }
    }

  },
  getters: {
    // getField
  },
  mutations: {
    // updateField,
    SET_VIEW_MODE (state, payload) {
      myLocalStorage.setItem('viewmode', payload)
      state.viewMode = payload
    },
    SET_IN_EXECUTION (state, payload) {
      myLocalStorage.setItem('laudite', payload)
      state.inExecution = payload
    },
    SET_EVENT_EMIT (state, payload) {
      state.eventEmit = payload
    },
    SET_IFRAME (state, payload) {
      state.iframe = payload
    },
    SET_URL (state, payload) {
      state.url = payload
    },
    SET_LISTENER (state, payload) {
      state.existeListener = payload
    },
    SET_PROXIMO (state, payload) {
      state.proximo = payload
    },
    SET_TOKEN (state, payload) {
      if (payload) {
        state.companyToken = '5f4ccc9e1b0f137dfdea7bba' // telelaudo
      } else {
        state.companyToken = '60dc9862e35cb25c26cdbc92' // Genesis
      }
    }
  }
}
