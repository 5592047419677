import api from "@/services/api";
import { getField, updateField } from "vuex-map-fields";
import moment from "moment";
import { myLocalStorage } from "@/mixins/localstore";

const getDefaultState = () => {
  return {
    Grid: {
      timer: null,
      Order: myLocalStorage.getItem("Order")
        ? JSON.parse(myLocalStorage.getItem("Order"))
        : ["dt_data_hora"],
      OrderDesc: myLocalStorage.getItem("OrderDesc")
        ? JSON.parse(myLocalStorage.getItem("OrderDesc"))
        : [false],
      Lines: myLocalStorage.getItem("Lines")
        ? JSON.parse(myLocalStorage.getItem("Lines"))
        : 10,
    },
    Filtro: {
      cd_atendimento: 0,
      cd_paciente: "",
      dt_data: moment().subtract(1, "month").format("DD/MM/YYYY"),
      dt_data_ate: moment().format("DD/MM/YYYY"),
      nr_controle: "",
      nr_filtro: 1,
      nr_data: 1,
      cd_empresa: 0,
      cd_funcionario: 0,
    },
    Rotas: JSON.parse(myLocalStorage.getItem("rotas")) || {
      cd_atendimento: 0,
      cd_exame: 0,
      cd_paciente: 0,
      cd_laudo: 0,
    },
    listaAtendimento: [],
    listaAlerta: [],
    listaFiltro: [],
    listaModalidade: [],
    totalChat: "",
    alerta: "",
  };
};

const state = Object.assign({}, getDefaultState());

export default {
  namespaced: true,
  state,
  getters: {
    getField,
    GetRota(state) {
      return state.Rotas;
    },
  },
  mutations: {
    updateField,
    SetRota(state, payload) {
      state.Rotas = { ...payload }; // ...state.Rotas
      myLocalStorage.setItem("rotas", JSON.stringify(state.Rotas));
    },
    SET_ROTA(state, payload) {
      state.Rotas = { ...payload }; // ...state.Rotas
      myLocalStorage.setItem("rotas", JSON.stringify(state.Rotas));
    },
    SET_ALERTA(state, payload) {
      state.alerta = payload;
    },
    SET_LISTA_FILTRO(state, payload) {
      state.listaFiltro = payload;
    },
    SET_LISTA(state, payload) {
      state.listaAtendimento = payload;
      state.Grid.timer = moment();
    },
    SET_LISTA_ALERTA(state, payload) {
      state.listaAlerta = payload;
    },
    SET_LISTA_MODALIDADE(state, payload) {
      state.listaModalidade = payload;
    },
    SET_CHAT(state, payload) {
      state.totalChat = payload;
    },
    SET_ORDER(state, payload) {
      state.Grid.Order = payload.sortBy;
      state.Grid.OrderDesc = payload.sortDesc;
      state.Grid.Lines = payload.itemsPerPage;
      myLocalStorage.setItem("Order", JSON.stringify(payload.sortBy));
      myLocalStorage.setItem("OrderDesc", JSON.stringify(payload.sortDesc));
      myLocalStorage.setItem("Lines", JSON.stringify(payload.itemsPerPage));
    },
    // SET_FILTRO (state) {
    //   myLocalStorage.setItem('filtro', JSON.stringify(state.Filtro))
    //   // myLocalStorage.setItem('grid', JSON.stringify(state.Grid))
    // },
    RESET(state) {
      Object.assign(state, getDefaultState());
    },
    RESET_ROTA(state) {
      Object.assign(state, getDefaultState());
    },
    RESET_FILTRO(state) {
      myLocalStorage.removeItem("Order");
      myLocalStorage.removeItem("OrderDesc");
      myLocalStorage.removeItem("Lines");
      state.Grid.Order = ["dt_data_hora"];
      state.Grid.OrderDesc = [false];
      state.Grid.Lines = 10;
    },
  },
  actions: {
    exibeAlerta({ commit }) {
      api()
        .post("se1/doTeleAlerta")
        .then((response) => {
          commit("SET_ALERTA", response?.data[0]?.ds_alerta_paciente);
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e }, { root: true });
        });
    },
    carregarAlertas({ state, commit }) {
      const filtro = Object.assign({}, state.Filtro);
      filtro.sn_chat = true;
      api()
        .post("se1/doTeleLista", filtro)
        .then((response) => {
          commit("SET_LISTA_ALERTA", response.data || []);
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e }, { root: true });
        });
    },
    carregarModalidade({ commit }, payload) {
      return api()
        .post("se1/doListaModalidade", payload)
        .then((response) => {
          commit("SET_LISTA_MODALIDADE", response.data);
          return response;
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e }, { root: true });
        });
    },
    carregarAtendimento({ state, commit, rootState }) {
      const payload = state.Filtro;
      payload.cd_empresa = rootState.login.user.cd_empresa;
      payload.cd_funcionario = rootState.login.user.cd_funcionario;

      const filtro = Object.assign({}, state.Filtro);
      filtro.sn_chat = false;
      api()
        .post("se1/doTeleLista", filtro)
        .then((response) => {
          commit("SET_LISTA", response.data);
          // commit('SET_FILTRO')
          commit("SET_ROTA", { cd_atendimento: 0, cd_paciente: 0 });
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e }, { root: true });
        });
    },
    carregarFiltro({ commit }) {
      api()
        .post("se1/doTeleFiltro")
        .then((response) => {
          commit("SET_LISTA_FILTRO", response.data);
        })
        .catch((e) => {
          commit("layout/SET_ALERTA", { mensagem: e }, { root: true });
        });
    },
    /*
    countChat ({ rootState, commit }) {
      if (rootState.login.user.cd_empresa) {
        // api().post('se1/doSQLExec', { sql: `select sum(nr_chat_clinica) as nr_total from atendimentos ae join salas sa using(cd_sala) where sa.cd_empresa = ${rootState.login.user.cd_empresa} and ae.dt_data > now() :: date - 30` })
        api().post('se1/doChatTotal', { cd_empresa: rootState.login.user.cd_empresa })
          .then(response => {
            if (response.data && response.data[0].nr_total > 0) {
              commit('SET_CHAT', `Novos alertas (${response.data[0].nr_total})`)
            } else {
              commit('SET_CHAT', '')
            }
          })
      } else {
        commit('layout/SET_ALERTA', { mensagem: 'Empresa não definida' }, { root: true })
      }
    }
    */
  },
};
