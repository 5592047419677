import axios from 'axios'
import store from '@/store'
import router from '@/router'
import moment from 'moment'
import api from './api'

const defaultOptions = {
  headers: {
    'Content-Type': 'text/plain'
  },
  timeout: 30000,
  transformRequest: [function (data) {
    data = data || {}
    data.token = store.getters['login/token']
    return JSON.stringify(data)
  }]
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(config => {
  const valid = moment(store.state.login.valid)
  if (valid && moment().diff(valid, 'minutes') > 30 && moment().diff(valid, 'minutes') < 60) {
    api().post('se1/getToken')
  }
  config.baseURL = axiosInstance.defaults.baseURL = store.state.cgi.cliente.url
  store.commit('layout/START_LOADING')
  return config
}, error => {
  this.$store.commit('layout/FINISH_LOADING')
  console.error(error)
})

axiosInstance.interceptors.response.use(response => {
  store.commit('layout/FINISH_LOADING')
  return response
}, e => {
  store.commit('layout/FINISH_LOADING')
  switch (e.response.status) {
    case 401:
      e = new Error('Usuário ou sessão inválida !')
      store.dispatch('login/logout').then(() => {
        router.push({ name: 'anamneseLogin' })
      })
      break
  }
  return Promise.reject(e)
})

export default () => {
  return axiosInstance
}
