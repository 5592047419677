import axios from 'axios'
import store from '@/store'
// import { myLocalStorage } from '@/mixins/localstore'

function SetParam (params) {
  const formData = new FormData()
  for (const key in params) {
    if ({}.toString.call(params[key]) !== '[object Function]') {
      formData.append(key, params[key])
      if (key === 'urlPdf') {
        return JSON.stringify(params)
      }
    }
  }
  return formData
}

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
  transformRequest: [function (data, headers) {
    data = data || {}
    // data.token = myLocalStorage.getItem('token')
    if (data.type?.toLowerCase() === '.html') {
      return JSON.stringify(data)
    }
    return SetParam(data)
  }]
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(config => {
  store.commit('layout/START_LOADING')
  return config
}, error => {
  this.$store.commit('layout/FINISH_LOADING')
  console.error(error)
  // Promise.reject(error)
})

axiosInstance.interceptors.response.use(response => {
  store.commit('layout/FINISH_LOADING')
  return response
}, error => {
  store.commit('layout/FINISH_LOADING')
  console.error(error)
  // return Promise.reject(error)
})

export default () => {
  if (window.location.href.includes('telelaudo-homo')) {
    axiosInstance.defaults.baseURL = 'https://editor.hmg.telelaudo.com.br'
  } else if (window.location.href.includes('telelaudo')) {
    axiosInstance.defaults.baseURL = 'https://editor.telelaudo.com.br'
  } else {
    axiosInstance.defaults.baseURL = 'https://editor.clinux.com.br'
  }
  //axiosInstance.defaults.baseURL = 'https://editor.clinux.com.br'
  axiosInstance.defaults.baseURL = 'https://editor.telelaudo.com.br'
  return axiosInstance
}
