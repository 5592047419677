import api from '@/services/api'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import { toReal } from '@/mixins/utils'

const filtroInicial = {
  dt_de: moment().subtract(1, 'month').format('DD/MM/YYYY'),
  dt_ate: moment().format('DD/MM/YYYY'),
  sn_realizado: true
}

const totalInicial = {
  totalParticular: 0,
  totalConvenio: 0,
  total: 0
}

export default {
  namespaced: true,
  state: {
    repasse: [],
    filtro: Object.assign({}, filtroInicial),
    valores: []
  },
  actions: {
    doMedicoRepasse ({ state, commit, rootState }) {
      const filtro = Object.assign({}, state.filtro)
      filtro.cd_medico = rootState.login.user.cd_medico
      api().post('se1/doMedicoRepasse', filtro).then(response => {
        const repasse = response.data || []
        const valores = Object.assign({}, totalInicial)
        repasse.forEach(item => {
          valores.totalConvenio += item.nr_rp_convenio
          valores.totalParticular += item.nr_rp_particular
          valores.total += item.nr_rp_total
        })
        commit('SET_REPASSE', repasse)
        commit('SET_VALORES', valores)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_REPASSE (state, payload) {
      state.repasse = payload
    },
    SET_VALORES (state, payload) {
      const valores = []
      payload.totalConvenio = toReal(payload.totalConvenio || 'R$ 0,0')
      payload.totalParticular = toReal(payload.totalParticular || 'R$ 0,0')
      payload.total = toReal(payload.total || 'R$ 0,0')

      valores.push(payload)

      state.valores = Object.assign([], valores)
    },
    RESET (state) {
      state.filtro = Object.assign({}, filtroInicial)
    }
  }
}
