import api from '@/services/api'
import ApiLaudo from '@/services/laudo'
import { getField, updateField } from 'vuex-map-fields'
import { myLocalStorage } from '@/mixins/localstore'
import { saveAs } from 'file-saver'



export default {
  namespaced: true,
  state: {
    atendimento: myLocalStorage.getItem('atendimento') ? JSON.parse(myLocalStorage.getItem('atendimento')) : {},
    rascunho: myLocalStorage.getItem('rascunho') ? JSON.parse(myLocalStorage.getItem('rascunho')) : {},
    laudo: myLocalStorage.getItem('laudo')
      ? JSON.parse(myLocalStorage.getItem('laudo'))
      : {
        cd_exame: 0,
        cd_medico: 0,
        bb_laudo: null,
        bb_html: null,
        ds_exame: '',
        sn_provisorio: false
      },
    original: {},
    // recognition: null,
    IaraConsole: '',
    iaraMinimumData: {},
    // autotexto: '',
    classificacao: 0,
    tipoVariaveis: 'modelo',
    headerFooter: {},
    sfdtVariaveis: '',
    layout: myLocalStorage.getItem('layout') ? JSON.parse(myLocalStorage.getItem('layout')) : {},
    laudoSfdt: {},
    modelo: {},
    achado: {},
    complemento: {},
    listaExames: [],
    listaGrupos: [],
    listaModelos: [],
    listaModelosHtml: [],
    listaChaves: [],
    listaAchados: [],
    listaLaudoExterno: [],
    listaFiltroCancela: [],
    listaLaudoFiltroTipo: [],
    listaLaudoFiltroRevisao: [],
    listaComplementos: [],
    listaHistorico: [],
    listaModeloChave: [],
    compressoes: [],
    filmes: [],
    viewers: []
  },
  getters: {
    /*
    recognition (state, getters, rootState, rootGetters) {
      if (!state.recognition) {
        state.recognition = new IaraSpeechRecognition()
        const recognition = state.recognition
        // declarar state.recognition e depois referenciar esse objeto em uma
        // const e necessario para: fazer do objeto um singleton e evitar que
        // multiplas requisicoes a api da iara sejam feitas.
        recognition.commands.add('iara texto (\\p{Letter}+)', (detail, command, param, groups) => {
          console.log(detail, command, param)
          state.IaraConsole = groups[1]
          rootState.actions.doLaudoFiltroTexto(groups[1])
        });
        recognition.onstart = () => {
          state.IaraConsole = 'Gravação em andamento.'
        }
        recognition.onstop = () => {
          state.IaraConsole = 'Gravação finalizada.'
        }
        recognition.addEventListener("iaraSpeechRecognitionVADVoiceStart", (event) => {
          state.IaraConsole = 'Atividade de voz detectada ...'
          console.log(JSON.stringify(event.detail, null, 4));
        }
        );
        recognition.addEventListener("iaraSpeechRecognitionVADVoiceStop", (event) => {
          state.IaraConsole = 'Paramos de detectar atividade de voz, começe a falar novamente para transcrevermos.'
          console.log(JSON.stringify(event.detail, null, 4));
        }
        );
        return recognition
      }
      return state.recognition
    },*/
    getField
  },
  actions: {
    validarCertificado ({ commit, rootState }) {
      if (rootState.login.user.sn_certificado) {
        return api().post('se1/doMedicoToken').then(response => {
          if (!response.data.token) {
            if (response.data.url) {
              window.open(response.data.url + '/autenticarmedico', '_blank', 'noreferrer')              
            } else {              
              commit('layout/SET_ALERTA', { mensagem: 'Url do token não informada !' }, { root: true })
            }
          } 
          return response.data.token
        }).catch(error => {
          commit('layout/SET_ALERTA', { mensagem: error.message }, { root: true })
        })
      } else {
        return true
      }
    },
    doLaudoDados ({ commit }, payload) {
      return api().post('se1/doLaudoDados', { cd_exame: payload }).then(response => {
        if (response.data[0].bb_dados) {
          response.data[0].bb_dados = atob(response.data[0].bb_dados)
        }
        commit('SET_ATENDIMENTO', response.data[0])
        return response
      })
    },
    doLaudoAbrir ({ commit }, payload) {
      return api().post('se1/doLaudoAbrir', { cd_exame: payload.exame, cd_medico: payload.medico }).then(response => {
        commit('SET_LAUDO', response.data[0])
        commit('SET_MEDICO', payload.medico)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoAgrupar ({ commit }, payload) {
      return api().post('se1/doLaudoAgrupar', { cd_exame: payload.cd_exame }).then(response => {
        commit('SET_GRUPOS', response.data)
        commit('SET_EXAMES', [payload.cd_exame])
        return response
      })
    },
    doLaudoRevisao (payload) {
      return api().post('se1/doLaudoRevisao', { cd_atendimento: payload.cd_atendimento })
    },
    doLaudoGravar ({ state, commit }) {
      return api().post('se1/doLaudoGravar', state.laudo).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoSair ({ state, commit }) {
      if (state.atendimento.cd_exame) {
        return api().post('se1/doLaudoSair', { cd_exame: state.atendimento.cd_exame }).then(response => {
          commit('RESET')
          commit('editor/SET_SFDT', null, { root: true })
          return response
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
      }
    },
    doLaudoCancelar ({ commit }, payload) {
      return api().post('se1/doLaudoCancelar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoFiltroCancela ({ commit }) {
      return api().post('se1/doLaudoFiltroCancela').then(response => {
        commit('SET_LISTA_FILTRO_CANCELA', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoAssinar ({ state, commit }) {
      return api().post('se1/doLaudoAssinar', state.laudo).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoAuditar ({ state, commit }, payload) {
      return api().post('se1/doLaudoAuditar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoFiltroTipo ({ commit }, payload) {
      return api().post('se1/doLaudoFiltroTipo', payload).then(response => {
        commit('SET_LAUDO_FILTRO_TIPO', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoFiltroRevisao ({ commit }, payload) {
      return api().post('se1/doLaudoFiltroRevisao', payload).then(response => {
        commit('SET_LAUDO_FILTRO_REVISAO', response.data || [])
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoProximo ({ commit }) {
      return api().post('se1/doLaudoProximo').then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doModeloLayout ({ state, commit }) {
      return api().post('se1/doModeloLayout', { cd_exame: state.atendimento.cd_exame }).then(response => {
        return ApiLaudo.RtfToSfd(response.data[0].bb_layout).then(response => {
          commit('SET_LAYOUT', response.data)
          return response
        })
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doModeloAbrir ({ commit }, payload) {
      return api().post('se1/doModeloAbrir', { cd_modelo: payload }).then(response => {
        if (response.data[0]?.bb_modelo) {
          const modelo = atob(response.data[0].bb_modelo)
          if (modelo.match(/^<html/gmi)) {
            return ApiLaudo.HtmlToSfd(response.data[0].bb_modelo).then(response => {
              commit('SET_MODELO', response.data)
              return response
            })
          } else {
            return ApiLaudo.RtfToSfd(response.data[0].bb_modelo).then(response => {
              commit('SET_MODELO', response.data)
              return response
            })
          }
        }
      }).catch(e => {
        console.log('doModeloAbrir')
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    carregarModelo ({ dispatch, commit, rootState }, payload) {
      return dispatch('doModeloLayout').then(() => {
        return dispatch('doModeloAbrir', payload).then(() => {
          return dispatch('doLaudoModeloChave', { cd_modelo: payload, tipo: 'modelo' }).then(response => {
            if (!response?.data?.length) {
              commit('SET_BODY', rootState)
            }
            return response
          })
        })
      })
    },
    doModeloLista ({ state, commit }, payload) {
      return api().post('se1/doModeloLista', { cd_exame: state.atendimento.cd_exame, sn_todos: payload?.sn_todos, sn_html: payload?.sn_html }).then(response => {
        commit('SET_LISTA_MODELO', response.data)
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAchadoLista ({ commit }) {
      api().post('se1/doAchadoLista').then(response => {
        commit('SET_LISTA_ACHADO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doComplementoLista ({ commit }) {
      api().post('se1/doComplementoLista').then(response => {
        commit('SET_LISTA_COMPLEMENTO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    autoTexto ({ rootState }, payload) {
      // commit('SET_LISTA_CHAVE', response.data)
      if (atob(payload.data[0]?.bb_html).includes('{\\rtf1')) {
        return ApiLaudo.RtfToSfd(payload.data[0].bb_html).then(response => {
          // commit('SET_AUTO_TEXTO', response.data)
          rootState.editor.editor.editor.paste(JSON.stringify(response.data))
          // return Promise.resolve(true)
          return response.data
        })
      } else if (payload.data[0].bb_html) {
        // commit('SET_AUTO_TEXTO', atob(payload.data[0].bb_html))
        rootState.editor.editor.editor.insertText(atob(payload.data[0].bb_html))
        // return Promise.resolve(true)
        return atob(payload.data[0].bb_html)
      }
    },
    doLaudoFiltroTexto ({ state, rootState, commit, dispatch }, payload = null) {
      return api().post('se1/doLaudoFiltroTexto', {
        cd_exame: state.atendimento.cd_exame,
        cd_medico: rootState.login.user.cd_medico,
        ds_texto: payload
      }).then(response => {
        if (response.data) {
          if (response.data.length === 1 && payload !== '%') {
            if (response.data[0]?.modelo) {
              return dispatch('doLaudoModeloChave', { cd_modelo: response.data[0].modelo, tipo: 'texto' }).then(res => {
                if (res.data?.length) {
                  commit('SET_SFDT_VARIAVEIS', response)
                } else {
                  dispatch('autoTexto', response)
                }
                return response
              })
            } else {
              dispatch('autoTexto', response)
            }
          } else if (response.data.length > 0) {
            // dispatch('doLaudoFiltroTexto')
            commit('SET_LISTA_CHAVE', response.data)
          } else {
            commit('SET_LISTA_CHAVE', response.data)
          }
          return response
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doModeloExportar ({ commit }, payload) {
      api().post('se1/doModeloExportar', { cd_exame: payload.exame }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doMedicoAssinatura ({ commit }, payload) {
      api().post('se1/doMedicoAssinatura', { cd_medico: payload.medico }).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoPesquisa ({ commit }, payload) {
      return api().post('se1/doLaudoLista', payload).then(response => {
        commit('SET_LAUDO', response?.data[0])
        return response?.data[0]
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoLista ({ commit }, payload) {
      api().post('se1/doLaudoLista', { cd_paciente: payload.paciente, sn_assinado: true }).then(response => {
        commit('SET_HISTORICO', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doAchadoGravar ({ commit }, payload) {
      return api().post('se1/doAchadoGravar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    abrirAchado ({ commit }, payload) {
      commit('SET_ACHADO', payload)
    },
    abrirComplemento ({ commit }, payload) {
      commit('SET_COMPLEMENTO', payload)
    },
    doComplementoGravar ({ commit }, payload) {
      return api().post('se1/doComplementoGravar', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    salvarRtf ({ rootState }) {
      return rootState.editor.editor.saveAsBlob('Docx').then(exportedDocument => {
        return ApiLaudo.SfdToRtf(exportedDocument)
      })
    },
    doDicomCompressao ({ commit }) {
      api().post('se1/doDicomCompressao').then(response => {
        commit('SET_COMPRESSOES', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doLaudoVisualizar ({ commit }, payload) {
      return api().post('se1/doLaudoVisualizar', { cd_exame: payload }).then(response => {
        const rtf = response.data[0]?.bb_laudo ? atob(response.data[0].bb_laudo) : null
        if (rtf?.includes('{\\rtf1')) {
          return ApiLaudo.RtfToSfd(response.data[0].bb_laudo).then(response => {
            if (response.data.sections[0]) {
              commit('SET_LAUDO_SFDT', response.data)
            } else {
              commit('layout/SET_ALERTA', { mensagem: 'Laudo inválido !' }, { root: true })
            }
            return response
          })
        }
      }).catch(e => {
        console.log('doLaudoVisualizar')
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doDicomFilme ({ commit, state }) {
      api().post('se1/doDicomFilme', {
        cd_atendimento: state.atendimento.cd_atendimento,
        nr_controle: state.atendimento.nr_controle,
        cd_exame: state.atendimento.cd_exame
      }).then(response => {
        commit('SET_FILMES', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doDicomDownloadLink ({ commit, rootState }, payload) {
      /*
      payload.token = rootState.login.token
      api().post('se1/doDicomDownloadLink', payload, {
        timeout: 0 // Timeout desabilitado até retorno do servidor
      }).then(response => {
        if (response.data && response.data[0].Sucesso) {
          window.open(`${rootState.cgi.cliente.tmp}/temp/${response.data[0].Sucesso}`, '_blank')
        } else {
          commit('layout/SET_ALERTA', {
            mensagem: 'Não foi possível gerar arquivo para download',
            tipo: 'warning'
          }, { root: true })
        }
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
      */
      const params = {
        cd_atendimento: payload.cd_atendimento,
        cd_exame: payload.cd_exame,
        cd_filme: payload.cd_filme,
        cd_compressao: payload.cd_compressao,
        nr_controle: payload.nr_controle,
        token: rootState.login.user.ds_token
      }
      const url = api().defaults.baseURL
      let urlDownload = ''
      if ((url.includes('https') || url.includes('localhost'))) {
        urlDownload = `${api().defaults.baseURL}/www/doGetDicom?${new URLSearchParams(params).toString()}`
      } else {
        const urlParams = JSON.stringify(params)
        urlDownload = `${api().defaults.baseURL}/www/doGetDicom?params=${urlParams}&endpoint=${rootState.cgi.cliente.url}/www/doLaudoDownload`
      }

      api().get(urlDownload, {
        responseType: 'blob',
        timeout: 0,
        onDownloadProgress: (progressEvent) => {
          commit('anexos/SET_MODO', 'Download', { root: true })
          commit('anexos/SET_DS_ARQUIVO', 'Imagem do atendimento', { root: true })
          commit('anexos/SET_PORCENTAGEM', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)), { root: true })
        }
      }).then(response => {
        commit('anexos/RESET_PROGRESSO', {}, { root: true })
        saveAs(response.data, `${payload.nr_controle}.zip`)
      }).catch(e => {
        commit('anexos/RESET_PROGRESSO', {}, { root: true })
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    carregarViewers ({ commit, rootState }) {
      const setup = rootState.login.setup
      const snMacOs = navigator.userAgent.match(/\(mac/gmi)

      const viewers = [
        { icone: 'mdi-image-area-close', texto: 'Jpeg', acao: 'jpeg', exibir: setup.sn_dicomvix_filme },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Java)', acao: 'weasis', exibir: setup.sn_dicomvix_dicom },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Web)', acao: 'ohif', exibir: setup.sn_dicomvix_dicom },
        { icone: 'mdi-image-area-close', texto: 'Dicom (Radiant)', acao: 'radiant', exibir: setup.sn_dicomvix_dicom },
        { icone: 'mdi-image-area', texto: 'DicomVix Mac', acao: 'osirix', exibir: snMacOs && setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'DicomVix Mac Open', acao: 'osirix-open', exibir: snMacOs && setup.sn_dicomvix_wado },
        { icone: 'mdi-image-area', texto: 'DicomVix Mac Click', acao: 'osirix-onclick', exibir: snMacOs && setup.sn_dicomvix_wado }
      ].filter(v => v.exibir)
      commit('SET_VIEWERS', viewers)
    },
    doFuncionarioAcesso ({ rootState, commit }) {
      return api().post('se1/doFuncionarioAcesso', {
        cd_funcionario: rootState.login.user.cd_funcionario,
        ds_form: 'GER_MEDICO_EXE_LAUDO'
      }).then(response => {
        return response.data.length === 0
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        return false
      })
    },
    doLaudoModeloChave ({ commit, dispatch }, payload) {
      return api().post('se1/doLaudoModeloChave', {
        cd_modelo: payload.cd_modelo
      }).then(response => {
        if (response.data[0]?.Error || response.data[0]?.Falha) {
          this.$store.dispatch('layout/definirAlerta', { ativo: true, mensagem: response.data[0]?.Error || response.data[0]?.Falha })
        } else if (response.data?.length) {
          const chaves = response.data
          /*
          chaves.forEach(chave => {
            if (chave.nr_tipo.toLowerCase() === 'l') {
              dispatch('doLaudoModeloChaveLista', chave.cd_chave).then(response => (chave.lista = response))
            }
          })
          */
          commit('SET_LISTA_MODELO_CHAVE', chaves)
          commit('SET_TIPO_VARIAVEIS', payload.tipo)
        } else if (payload.tipo === 'texto') {
          commit('layout/SET_ALERTA', { mensagem: 'Lista de Variáveis Vazia !' }, { root: true })
          return false
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        return false
      })
    },
    doLaudoModeloChaveLista ({ commit }, payload) {
      return api().post('se1/doLaudoModeloChaveLista', {
        cd_chave: payload
      }).then(response => {
        if (response.data[0]?.Error || response.data[0]?.Falha) {
          this.$store.dispatch('layout/definirAlerta', { ativo: true, mensagem: response.data[0]?.Error || response.data[0]?.Falha })
          return null
        }
        return response.data || []
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        return false
      })
    },
    doLaudoExternoLista ({ commit }, payload) {
      return api().post('se1/doLaudoExternoLista', {
        cd_exame: payload
      }).then(response => {
        if (response.data[0]?.Error || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Error || response.data[0]?.Falha }, { root: true })
        } else {
          commit('SET_LISTA_LAUDO_EXTERNO', response.data || [])
        }
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        return false
      })
    },
    fluxoRascunho ({ commit, rootState, state }, payload) {
      const saveDraft = () => {
        const rascunho = {
          cd_exame: state.atendimento.cd_exame,
          bb_laudo: rootState.editor.editor ? rootState.editor.editor.serialize() : null,
          bb_reset: state.original
        }
        commit('SET_RASCUNHO', rascunho)
      }
      if (payload) {
        window.addEventListener('beforeunload', saveDraft, false)
      } else {
        window.removeEventListener('beforeunload', saveDraft)
      }
      return Promise.resolve(state.rascunho)
    },
    doCadastroChave ({ commit }, payload) {
      return api().post('se1/doCadastro', payload).then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    doMedicoToken ({ commit }) {
      return api().post('se1/doMedicoToken').then(response => {
        return response
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }

  },
  mutations: {
    updateField,
    SET_GRUPOS (state, payload) {
      state.listaGrupos = payload
    },
    SET_EXAMES (state, payload) {
      state.listaExames = payload
    },
    SET_COMPRESSOES (state, payload) {
      state.compressoes = payload
    },
    SET_FILMES (state, payload) {
      state.filmes = payload
    },
    SET_ATENDIMENTO (state, payload) {
      state.atendimento = payload
      state.listaModelos = []
      state.listaChaves = []
      myLocalStorage.setItem('atendimento', JSON.stringify(payload))
    },
    SET_LAUDO (state, payload) {
      if (payload) {
        state.laudo = payload
        myLocalStorage.setItem('laudo', JSON.stringify(payload))
      } else {
        state.laudo = {
          cd_exame: 0,
          cd_medico: 0,
          bb_laudo: null,
          bb_html: null,
          ds_exame: '',
          sn_provisorio: false
        }
        myLocalStorage.removeItem('laudo')
      }
    },
    SET_MEDICO (state, payload) {
      state.laudo.cd_medico = payload
    },
    SET_LISTA_MODELO (state, payload) {
      state.listaModelos = payload
    },
    SET_LISTA_MODELO_HTML (state, payload) {
      state.listaModelosHtml = payload
    },
    SET_LISTA_ACHADO (state, payload) {
      state.listaAchados = payload
    },
    SET_LISTA_MODELO_CHAVE (state, payload) {
      state.listaModeloChave = payload
    },
    SET_LISTA_FILTRO_CANCELA (state, payload) {
      state.listaFiltroCancela = payload
    },
    SET_LAUDO_FILTRO_TIPO (state, payload) {
      state.listaLaudoFiltroTipo = payload
    },
    SET_LAUDO_FILTRO_REVISAO (state, payload) {
      state.listaLaudoFiltroRevisao = payload
    },
    SET_TIPO_VARIAVEIS (state, payload) {
      state.tipoVariaveis = payload
    },
    SET_LISTA_COMPLEMENTO (state, payload) {
      state.listaComplementos = payload
    },
    SET_LISTA_CHAVE (state, payload) {
      state.listaChaves = payload
    },
    SET_VIEWERS (state, payload) {
      state.viewers = payload
    },
    SET_LAUDO_SFDT (state, payload) {
      state.laudoSfdt = payload
    },
    SET_HISTORICO (state, payload) {
      state.listaHistorico = payload
    },
    // SET_AUTO_TEXTO (state, payload) {
    //  state.autotexto = payload
    // }, 
    SET_LAYOUT (state, payload) {
      state.layout = payload
      myLocalStorage.setItem('layout', JSON.stringify(payload))
    },
    SET_MODELO (state, payload) {
      state.modelo = payload
    },
    SET_ACHADO (state, payload) {
      state.achado = payload
    },
    SET_COMPLEMENTO (state, payload) {
      state.complemento = payload
    },
    SET_SFDT_VARIAVEIS (state, payload) {
      state.sfdtVariaveis = payload
    },
    SET_LISTA_LAUDO_EXTERNO (state, payload) {
      state.listaLaudoExterno = payload
    },
    SET_BODY (state, payload) {
      payload.editor.editor.openBlank()
      if (state.layout?.sections) {
        /*
        if (state.layout.sections[0].headersFooters?.firstPageHeader) {
          state.layout.sections[0].headersFooters.header = state.layout.sections[0].headersFooters.firstPageHeader
          delete state.layout.sections[0].headersFooters.firstPageHeader
        }
        */
        // Força espaçamento do modelo conforme definido no layout
        state.modelo.sections.forEach(section => {
          section.sectionFormat = state.layout?.sections[0]?.sectionFormat || section.sectionFormat
        })

        // payload.editor.sfdt = {}
        payload.editor.editor.open(state.layout)

        if (payload.editor.selection) {
          Promise.resolve(payload.editor.sfdt).then(() => {
            payload.editor.editor.editor.paste(JSON.stringify(state.modelo))
          })
        } else {
          payload.editor.editor.editor.paste(JSON.stringify(state.modelo))
        }
      }
    },
    SET_RASCUNHO (state, payload) {
      state.rascunho = payload
      myLocalStorage.setItem('rascunho', JSON.stringify(payload))
    },/*
    SET_RECOGNITION (state, payload) {
      state.recognition = payload
    },
    RESET_RECOGNITION (state) {
      state.recognition = null
    },*/
    RESET (state) {
      state.classificacao = 0
      state.laudo = {}
      state.achado = {}
      state.complemento = {}
      state.atendimento = {}
      state.layout = {}
      state.modelo = {}
      state.listaHistorico = []
      state.listaModelos = []
      state.listaChaves = []
      // myLocalStorage.removeItem('filtro')
      myLocalStorage.removeItem('laudo')
      myLocalStorage.removeItem('sfdt')
    },
  }
}
