import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    exame: {},
    medico: {},
    listaMedico: []
  },
  getters: {
    getField
  },
  actions: {
    abrirExame ({ commit, dispatch }, payload) {
      const objVazio = {
        cd_exame: 0,
        cd_atendimento: 0,
        cd_plano: 0,
        cd_procedimento: 0,
        nr_filme: '',
        cd_medico: '',
        cd_funcionario: 0
      }
      return new Promise((resolve, reject) => {
        api().post('se1/doCadastro', {
          ds_tabela: 'exames',
          cd_codigo: payload.id,
          cd_operacao: payload.op,
          js_tabela: btoa(JSON.stringify(payload.obj || objVazio))
        }).then(response => {
          response.data = response.data && response.data.length ? response.data : [objVazio]
          if (response.data[0].Erro || response.data[0].Falha) {
            commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0].Falha }, { root: true })
          } else if (payload.op === 3) {
            commit('SET_EXAME', response.data[0])
            dispatch('pesquisarMedico', { cd_medico: response.data[0].cd_medico })
          } else if (payload.op === 0) {
            commit('SET_KEY', response.data[0].Sucesso)
          }
          resolve(response)
        }).catch(e => {
          reject(e)
        })
      })
    },
    deleteExame (context, payload) {
      return new Promise((resolve, reject) => {
        api().post('se1/doFluxoExame', { cd_exame: payload }).then(response => {
          resolve(response)
        }).catch(e => {
          reject(e)
        })
      })
    },
    pesquisarMedico ({ state, commit }, payload) {
      const objVazio = {
        cd_medico: '',
        ds_medico: '',
        ds_crm_nr: '',
        ds_crm_uf: ''
      }
      if (!payload.cd_medico && !payload.ds_medico) {
        commit('SET_MEDICO', objVazio)
      } else {
        const item = state.listaMedico.findIndex(item => item.cd_medico === payload.cd_medico)
        if (payload.cd_medico && item >= 0) {
          commit('SET_MEDICO', state.listaMedico[item])
        } else {
          api().post('se1/doListaMedicoSol', { cd_medico: payload.cd_medico || 0, ds_medico: payload.ds_medico || '' })
            .then(response => {
              response.data = response.data?.length ? response.data : [objVazio]
              if (payload.cd_medico && response.data.length) {
                commit('SET_MEDICO', response.data[0])
              }
              commit('SET_LISTA_MEDICO', response.data)
            }).catch(e => {
              commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
            })
        }
      }
    }
  },
  mutations: {
    updateField,
    SET_EXAME (state, payload) {
      state.exame = payload
    },
    SET_KEY (state, payload) {
      state.exame.cd_exame = payload
    },
    SET_LISTA_MEDICO (state, payload) {
      state.listaMedico = payload
    },
    SET_MEDICO (state, payload) {
      state.medico = payload
    }
  }
}
