import api from '@/services/api'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'

const filtroInicial = {
  dt_de: moment().format('DD/MM/YYYY'), // moment().subtract(1, 'day').format('DD/MM/YYYY'),
  dt_ate: '', // moment().format('DD/MM/YYYY'),
  cd_sala: 0,
  cd_paciente: 0
}

export default {
  namespaced: true,
  state: {
    filtro: Object.assign({}, filtroInicial),
    agenda: [],
    legenda: [],
    salas: []
  },
  actions: {
    doMedicoAgenda ({ dispatch, commit, state }) {
      return api().post('se1/doMedicoAgenda', state.filtro).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        } else if (response?.data?.length) {
          if (!state.legenda.ds_cor_atendido) {
            dispatch('doAgendaLegenda').then(() => {
              commit('SET_AGENDA', response.data || [])
            })
          } else {
            commit('SET_AGENDA', response.data || [])
          }
        } else {
          commit('SET_AGENDA', response.data || [])
          // commit('layout/SET_ALERTA', { mensagem: 'Registro não encontrado !' }, { root: true })
        }
        return response.data
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },

    doAgendaLegenda ({ commit, state }) {
      if (!state.legenda?.length) {
        return api().post('se1/doAgendaLegenda').then(response => {
          if (response.data[0]?.Erro || response.data[0]?.Falha) {
            commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
          } else {
            commit('SET_LEGENDA', response.data || [])
          }
          return response.data
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
      } else {
        return Promise.resolve([])
      }
    },

    doMedicoSala ({ commit, rootState }) {
      return api().post('se1/doMedicoSala', {
        cd_medico: rootState.login.user.cd_medico
      }).then(response => {
        if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0]?.Erro || response.data[0]?.Falha }, { root: true })
        } else {
          commit('SET_SALAS', response.data || [])
        }
        return response.data
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    }
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    SET_AGENDA (state, payload) {
      if (state.legenda.sn_cor_atendimento) {
        payload.forEach(item => {
          switch (item.ds_status) {
            case 1:
              item.ds_legenda = `#${state.legenda.ds_cor_cancelado}`
              break
            case 2:
              item.ds_legenda = `#${state.legenda.ds_cor_reservado}`
              break
            case 3:
              item.ds_legenda = `#${state.legenda.ds_cor_confirmado}`
              break
            case 4:
              item.ds_legenda = `#${state.legenda.ds_cor_bloqueado}`
              break
            case 5:
              item.ds_legenda = `#${state.legenda.ds_cor_atendido}`
              break
            case 11:
              item.ds_legenda = `#${state.legenda.ds_cor_recepcao}`
              break
            case 14:
              item.ds_legenda = `#${state.legenda.ds_cor_congelado}`
              break
            default:
              item.ds_legenda = '#000'
              break
          }
        })
      }
      state.agenda = payload
    },
    SET_LEGENDA (state, payload) {
      state.legenda = payload[0]
    },
    SET_SALAS (state, payload) {
      state.salas = payload
    },
    RESET_FILTER (state) {
      state.filtro = Object.assign({}, filtroInicial)
    }
  }
}
