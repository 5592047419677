import api from '@/services/api'
import { getField, updateField } from 'vuex-map-fields'
import consts from '@/mixins/consts'

export default {
  namespaced: true,
  state: {
    formAtendimento: false,
    formExame: false,
    atendimento: {},
    sala: {},
    listaSla: [],
    listaExame: [],
    listaProcedimento: [],
    listaSala: [],
    listaUrgencia: [{ cd_urgente: true, ds_urgente: 'SIM' }, { cd_urgente: false, ds_urgente: 'NAO' }],
    listaProcedencia: [],
    listaContraste: ['NAO', 'EV', 'ORAL', 'EV E ORAL', 'RETAL']
  },
  getters: {
    getField
  },
  actions: {
    abrirAtendimento ({ commit, dispatch }, payload) {
      const objVazio = {
        cd_atendimento: 0,
        cd_paciente: '',
        nr_controle: '',
        dt_data: '',
        dt_hora: '',
        cd_sala: '',
        cd_medico: '',
        sn_urgencia: '',
        cd_procedencia: '',
        cd_sla: '',
        ds_contraste: '',
        bb_dados: '',
        ds_observacao: '',
        cd_funcionario: ''
      }
      return new Promise((resolve, reject) => {
        api().post('se1/doCadastro', {
          ds_tabela: 'atendimentos',
          cd_codigo: payload.id,
          cd_operacao: payload.op,
          js_tabela: btoa(JSON.stringify(payload.obj || objVazio))
        }).then(response => {
          response.data = response.data?.length ? response.data : [objVazio]
          if (response.data[0].Erro || response.data[0].Falha) {
            commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0].Falha }, { root: true })
          } else if (payload.op === 3) {
            commit('SET_ATENDIMENTO', response.data[0])
            dispatch('carregarSala', { sala: response.data[0].cd_sala })
          } else if (payload.op === 0) {
            commit('SET_KEY', response.data[0].Sucesso)
            commit('clinux/SetRota', { cd_atendimento: response.data[0].Sucesso }, { root: true })
          }
          resolve(response)
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
      })
    },
    carregarSla ({ commit, rootState }) {
      api().post('se1/doTeleSla', { cd_empresa: rootState.login.user.cd_empresa }).then(response => {
        commit('SET_LISTA_SLA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    carregarProcedencia ({ commit }) {
      api().post('se1/doListaProcedencia').then(response => {
        commit('SET_LISTA_PROCEDENCIA', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    carregarExame ({ commit }, payload) {
      api().post('se1/doListaExame', { cd_atendimento: payload || 0 }).then(response => {
        commit('SET_LISTA_EXAME', response.data)
      }).catch(e => {
        commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
      })
    },
    carregarSala ({ commit, state, rootState }, payload) {
      if (state.listaSala.findIndex(i => i.cd_sala === payload.sala) < 0) {
        api().post('se1/doListaSala', { cd_empresa: rootState.login.user.cd_empresa }).then(response => {
          commit('SET_LISTA_SALA', response.data)
          commit('SET_SALA_LISTA', payload.sala)
        }).catch(e => {
          commit('layout/SET_ALERTA', { mensagem: e }, { root: true })
        })
      } else {
        commit('SET_SALA_LISTA', payload.sala)
      }
    },
    carregarModalidade ({ commit }, payload) {
      if (payload.modalidade) {
        api().post('se1/doListaProcedimento', {
          cd_empresa: payload.empresa || 0,
          cd_modalidade: payload.modalidade || 0,
          cd_plano: payload.plano || 0
        }).then(response => {
          commit('SET_LISTA_PROCEDIMENTO', response.data)
        })
      } else {
        commit('SET_LISTA_PROCEDIMENTO', [])
      }
    },
    fluxoFicha (context, payload) {
      return new Promise((resolve, reject) => {
        api().post('se1/doFluxoFicha', { cd_atendimento: payload.atendimento, cd_funcionario: payload.funcionario }).then(response => {
          resolve(response)
        }).catch(e => {
          reject(e)
        })
      })
    },
    fluxoCancela ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api().post('se1/doFluxoCancela', { cd_atendimento: payload.atendimento }).then(response => {
          if (!response) {
            commit('layout/SET_ALERTA', { mmensagem: consts.msgPost }, { root: true })
          } else if (response.data[0].Sucesso) {
            commit('layout/SET_ALERTA', { mensagem: response.data[0].Sucesso, tipo: 'success' }, { root: true })
            commit('updateField', { path: 'atendimento.nr_controle', value: '' })
            commit('updateField', { path: 'formAtendimento', value: false })
          } else {
            commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0].Falha }, { root: true })
          }
          resolve(response)
        }).catch(e => {
          reject(e)
        })
      })
    },
    fluxoDeleta ({ commit }, payload) {
      return api().post('se1/doFluxoDeleta', { cd_atendimento: payload.atendimento }).then(response => {
        if (response.data[0]?.Sucesso) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Sucesso, tipo: 'success' }, { root: true })
        } else if (response.data[0]?.Erro || response.data[0]?.Falha) {
          commit('layout/SET_ALERTA', { mensagem: response.data[0].Erro || response.data[0]?.Falha }, { root: true })
        }
        return response
      }).catch(e => {
        return Promise.reject(e)
      })
    }
  },
  mutations: {
    updateField,
    SET_ATENDIMENTO (state, payload) {
      state.atendimento = payload
    },
    SET_KEY (state, payload) {
      state.atendimento.cd_atendimento = payload
    },
    SET_EXAME (state, payload) {
      state.exame = payload
    },
    SET_SALA (state, payload) {
      state.sala = payload
    },
    SET_LISTA_SLA (state, payload) {
      state.listaSla = payload
    },
    SET_LISTA_PROCEDENCIA (state, payload) {
      state.listaProcedencia = payload
    },
    SET_LISTA_SALA (state, payload) {
      state.listaSala = payload
    },
    SET_SALA_LISTA (state, payload) {
      state.sala = state.listaSala.find(i => i.cd_sala === payload)
    },
    SET_LISTA_EXAME (state, payload) {
      state.listaExame = payload
    },
    SET_LISTA_PROCEDIMENTO (state, payload) {
      state.listaProcedimento = payload
    }
  }
}
